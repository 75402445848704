import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  userLoginSuccess,
  userLoginFailure,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
  fetchOtherUserDetailsSuccess,
  fetchOtherUserDetailsFailure,
  updateSignAndApproveSuccess,
  updateSignAndApproveFailure,
  registerSuccess,
  registerFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPassswordFailure,
  verifyEmailSuccess,
  verifyEmailFailure,
  resendEmailVerificationCodeSuccess,
  resendEmailVerificationCodeFailure,
  twoStepAuthLoginSuccess,
  twoStepAuthLoginFailure,
  twoStepAuthResendCodeSuccess,
  twoStepAuthResendCodeFailure,
  updateTwoStepAuthSuccess,
  updateTwoStepAuthFailure,
  changePasswordSuccess,
  changePasswordFailure
} from "../actions/UserAction";
import api from "../../Environment";

import {
  FETCH_USER_DETAILS_START,
  FETCH_OTHER_USER_DETAILS_START,
  LOGIN_START,
  UPDATE_USER_DETAILS_START,
  UPDATE_SIGN_APPROVE_START,
  REGISTER_START,
  FORGOT_PASSWORD_START,
  RESET_PASSWORD_START,
  RESEND_EMAIL_VERIFICATION_CODE_START,
  VERIFY_EMAIL_START,
  TWO_STEP_AUTH_LOGIN_START,
  TWO_STEP_AUTH_RESEND_CODE_START,
  UPDATE_TWO_STEP_AUTH_START,
  CHANGE_PASSWORD_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";

function* getUserDetailsAPI() {
  try {
    const response = yield api.postMethod("profile");

    if (response.data.success) {
      yield put(fetchUserDetailsSuccess(response.data));
      localStorage.setItem("user_picture", response.data.data.user.picture);
      localStorage.setItem("user_unique_id", response.data.data.user.user_unique_id);
      localStorage.setItem("user_cover", response.data.data.user.cover);
      localStorage.setItem("username", response.data.data.user.username);
      localStorage.setItem("name", response.data.data.user.name);
    } else {
      yield put(fetchUserDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchUserDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* userLoginAPI(action) {
  try {

    const response = yield api.postMethod("login", {
      ...action.data, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    if (response.data.success) {
      yield put(userLoginSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      if (response.data.code == 1001 || response.data.data.user.is_two_step_auth_enabled === 1)
        localStorage.setItem("email", response.data.data.user.email);
      else {
        localStorage.setItem("wallet_address", action.data.wallet_address)
        localStorage.setItem("userLoginStatus", true);
        localStorage.setItem("user_picture", response.data.data.user.picture);
        localStorage.setItem("name", response.data.data.user.name);
        localStorage.setItem("user_unique_id", response.data.data.user.user_unique_id);
        localStorage.setItem("userId", response.data.data.user.user_id);
        localStorage.setItem("accessToken", response.data.data.user.token);
      }
    } else {
      yield put(userLoginFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(userLoginFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* registerAPI(action) {
  try {
    const response = yield api.postMethod("register", {
      ...action.data, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    if (response.data.success) {
      yield put(registerSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      if (response.data.code == 1001)
        localStorage.setItem("email", response.data.data.user.email);
      else {
        localStorage.setItem("userId", response.data.data.user.user_id);
        localStorage.setItem("accessToken", response.data.data.user.token);
        localStorage.setItem("wallet_address", action.data.wallet_address)
        localStorage.setItem("userLoginStatus", true);
        localStorage.setItem("user_picture", response.data.data.user.picture);
        localStorage.setItem("name", response.data.data.user.name);
        localStorage.setItem("user_unique_id", response.data.data.user.user_unique_id);
      }
    } else {
      yield put(registerFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(registerFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateUserDetailsAPI(action) {
  try {
    const response = yield api.postMethod("update_profile", action.data);

    if (response.data.success) {
      yield put(updateUserDetailsSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(updateUserDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(updateUserDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getOtherUserDetailsAPI(action) {
  try {
    const response = yield api.postMethod("other_user_profile", action.data);

    if (response.data.success) {
      yield put(fetchOtherUserDetailsSuccess(response.data));
    } else {
      yield put(fetchOtherUserDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchOtherUserDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* updateSignApproveAPI(action) {
  try {
    const response = yield api.postMethod("user_sign_approve_update", action.data);

    if (response.data.success) {
      yield put(updateSignAndApproveSuccess(response.data));
    } else {
      yield put(updateSignAndApproveFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(updateSignAndApproveFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* forgotPasswordAPI(action) {
  try {
    const response = yield api.postMethod("forgot_password", action.data);
    if (response.data.success) {
      yield put(forgotPasswordSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(forgotPasswordFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(forgotPasswordFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* resetPasswordAPI(action) {
  try {
    const response = yield api.postMethod("reset_password", action.data);
    if (response.data.success) {
      yield put(resetPasswordSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(resetPassswordFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(resetPassswordFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* verifyEmailAPI(action) {
  try {
    const response = yield api.postMethod("verify_email", action.data);
    if (response.data.success) {
      yield put(verifyEmailSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      localStorage.removeItem("email");
      localStorage.setItem("userId", response.data.data.user.user_id);
      localStorage.setItem("accessToken", response.data.data.user.token);
      localStorage.setItem("wallet_address", action.data.wallet_address)
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("user_picture", response.data.data.user.picture);
      localStorage.setItem("name", response.data.data.user.name);
      localStorage.setItem("user_unique_id", response.data.data.user.user_unique_id);
    } else {
      yield put(verifyEmailFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(verifyEmailFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* resendEmailVerificationCodeAPI(action) {
  try {
    const response = yield api.postMethod("regenerate_email_verification_code", action.data);
    if (response.data.success) {
      yield put(resendEmailVerificationCodeSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(resendEmailVerificationCodeFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(resendEmailVerificationCodeFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* twoStepAuthLoginAPI(action) {
  try {
    const response = yield api.postMethod("two_step_auth_login", action.data);
    if (response.data.success) {
      yield put(twoStepAuthLoginSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      localStorage.removeItem("email");
      localStorage.setItem("userId", response.data.data.user.user_id);
      localStorage.setItem("accessToken", response.data.data.user.token);
      localStorage.setItem("wallet_address", action.data.wallet_address)
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("user_picture", response.data.data.user.picture);
      localStorage.setItem("name", response.data.data.user.name);
      localStorage.setItem("user_unique_id", response.data.data.user.user_unique_id);
    } else {
      yield put(twoStepAuthLoginFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(twoStepAuthLoginFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* twoStepAuthResendCodeAPI(action) {
  try {
    const response = yield api.postMethod("two_step_auth_resend_code", action.data);
    if (response.data.success) {
      yield put(twoStepAuthResendCodeSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(twoStepAuthResendCodeFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(twoStepAuthResendCodeFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateTwoStepAuthAPI(action) {
  try {
    const response = yield api.postMethod("two_step_auth_update", action.data);
    if (response.data.success) {
      yield put(updateTwoStepAuthSuccess(response.data));
      yield put(fetchUserDetailsSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(updateTwoStepAuthFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(updateTwoStepAuthFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* changePasswordAPI(action) {
  try {
    const response = yield api.postMethod("change_password", action.data);
    if (response.data.success) {
      yield put(changePasswordSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(changePasswordFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(changePasswordFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_USER_DETAILS_START, getUserDetailsAPI),
    yield takeLatest(FETCH_OTHER_USER_DETAILS_START, getOtherUserDetailsAPI),
    yield takeLatest(LOGIN_START, userLoginAPI),
    yield takeLatest(REGISTER_START, registerAPI),
    yield takeLatest(UPDATE_USER_DETAILS_START, updateUserDetailsAPI),
    yield takeLatest(UPDATE_SIGN_APPROVE_START, updateSignApproveAPI),
    yield takeLatest(FORGOT_PASSWORD_START, forgotPasswordAPI),
    yield takeLatest(RESET_PASSWORD_START, resetPasswordAPI),
    yield takeLatest(VERIFY_EMAIL_START, verifyEmailAPI),
    yield takeLatest(RESEND_EMAIL_VERIFICATION_CODE_START, resendEmailVerificationCodeAPI),
    yield takeLatest(TWO_STEP_AUTH_LOGIN_START, twoStepAuthLoginAPI),
    yield takeLatest(TWO_STEP_AUTH_RESEND_CODE_START, twoStepAuthResendCodeAPI),
    yield takeLatest(UPDATE_TWO_STEP_AUTH_START, updateTwoStepAuthAPI),
    yield takeLatest(CHANGE_PASSWORD_START, changePasswordAPI),
  ]);
}
