import React from 'react';
import { useLocation, Link } from 'react-router-dom'
import { Col, Nav } from 'react-bootstrap';
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LockIcon from "@material-ui/icons/Lock";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

const SettingsSidebar = (props) => {
  const location = useLocation();

  return (
    <Col md={4} lg={3}>
      <div className="profile-setting-tabs-switcher">
        <h5 className="profile-heading">Settings</h5>
        <Nav variant="pills">
          <Nav.Item className="setting-tab active">
            <Link
              to="/profile-setting"
              className={`nav-link ${location.pathname === "/profile-setting" ? "active" : ""}`}>
              <AccountBoxIcon />
              Profile
            </Link>
          </Nav.Item>
          <Nav.Item className="setting-tab">
            <Link to="/authentication" className={`nav-link ${location.pathname === "/authentication" ? "active" : ""}`}>
              <LockIcon />
              Security
            </Link>
          </Nav.Item>
          <Nav.Item className="setting-tab">
            <Link to="/wallet" className={`nav-link ${location.pathname === "/wallet" || location.pathname === "/add-money" ? "active" : ""}`}>
              <AccountBalanceWalletIcon />
              Wallet
            </Link>
          </Nav.Item>
          <Nav.Item className="setting-tab">
            <Link to="/withdraw" className={`nav-link ${location.pathname === "/withdraw" || location.pathname === "/withdraw-money" ? "active" : ""}`}>
              <LocalAtmIcon />
              Withdraw
            </Link>
          </Nav.Item>
        </Nav>
      </div>
    </Col>
  )
}

export default SettingsSidebar;