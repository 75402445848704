import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Tab,
  Row,
  Table,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import SettingsSidebar from '../SettingsSidebar';
import './Wallet.css';
import {
  fetchWalletDetailsStart,
  fetchMoreWalletDetailsStart
} from '../../../store/actions/WalletAction';
import configuration from "react-global-configuration";
import NoDataFound from "../../NoDataFound/NoDataFound";
import CommonCenterLoader from "../../helper/CommonCenterLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";

const WalletIndex = (props) => {
  // const { auth } = useContext(authContext);

  useEffect(() => {
    props.dispatch(fetchWalletDetailsStart({
      skip: 0,
      take: 12
    }));
  }, []);

  const fetchMoreWalletDetails = () => {
    props.dispatch(fetchMoreWalletDetailsStart({
      skip: props.walletDetails.data.wallet_payments.length,
      take: 12,
    }))
  }

  const onCopy = () => {
    props.dispatch(getSuccessNotificationMessage("Transaction Id Copied"));
  }

  return (
    <>
      <div className="profile-setting pages-padding">
        <div className="profile-setting-wrapper">
          <Row className="mt-3 mt-md-0 g-0">
            <SettingsSidebar />
            <Col md={8} lg={9} className="mt-3 mt-md-0">
              <div className="wallet-wrapper">
                <div className="step-1">
                  <div className="wallet-header-sec">
                    <div className="wallet-current-balance">
                      <h4>{!props.walletDetails.loading &&
                        props.walletDetails.data.wallet
                        ? props.walletDetails.data.wallet.remaining_formatted
                        : "0.00 " + configuration.get("configData.currency")}</h4>
                      <p>Current wallet balance</p>
                    </div>
                    <div className="wallet-btn-sec">
                      <Link to="/add-money" className="default-btn border-radius-5 px-4 py-2">
                        <LibraryAddIcon />
                        <span className="mx-2">Add Money</span>
                      </Link>
                    </div>
                  </div>
                  <div className="wallet-table-sec">
                    <div className="section-title">
                      <h3 className="mb-3">Wallet History</h3>
                    </div>
                    {props.walletDetails.loading ?
                      <CommonCenterLoader />
                      : props.walletDetails.data.wallet_payments &&
                        props.walletDetails.data.wallet_payments.length > 0 ?
                        <div className="wallet-table-box">
                          <InfiniteScroll
                            dataLength={props.walletDetails.data.wallet_payments.length}
                            next={fetchMoreWalletDetails}
                            hasMore={props.walletDetails.data.wallet_payments.length < props.walletDetails.data.total}
                            loader={<CommonCenterLoader />}
                          >
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Transaction ID</th>
                                  <th>Amount</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {props.walletDetails.data.wallet_payments.map((payment, i) =>
                                  <tr>
                                    <td>{payment.paid_date_formatted}</td>
                                    <td className="text-center">
                                      {payment.payment_id ?
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{payment.payment_id}</Tooltip>}>
                                          <div className="transaction-id-sec">
                                            <CopyToClipboard text={payment.payment_id}
                                              onCopy={() => onCopy()}>
                                              <span>{payment.payment_id}</span>
                                            </CopyToClipboard>
                                          </div>
                                        </OverlayTrigger>
                                        : "-"}
                                    </td>
                                    <td>
                                      {payment.amount_type === "add" ? "+ " : "- "}
                                      {payment.requested_amount_formatted}
                                    </td>
                                    <td className="paid-color">
                                      {payment.message}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </InfiniteScroll>
                        </div>
                        : <NoDataFound />
                    }
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  walletDetails: state.wallet.walletDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WalletIndex);
