export const apiConstants = {
  // Common variables

  LOGIN_BY: "manual",

  DEVICE_TYPE: "web",

  DEVICE_TOKEN: "123456",

  // Social Login Credentials

  GOOGLE_CLIENT_ID: "",

  FACEBOOK_APP_ID: "",

  ERROR_CODE: [1000, 1001, 1002, 1003, 1004, 1005, 1006],

  google_api_key: "AIzaSyAfDkN9CGZJnwWXA93dH1EUnLgDkA-ohKg",

  settingsUrl:
    "https://cms-p1marketplace.blockstall.com/api/user/get_settings_json",

  // ipfs_project_id: "1zs1wmbIXY2SNhpne8PdSATeBlk",
  ipfs_project_id: "2DFOxa6mhVAiQGiqgHqbytDtYRT",

  // ipfs_project_secret: "a1e82ebaebf08c5ecf7526fc60adcda1"
  ipfs_project_secret: "f5fc3986a8bad1299751cd6b020c95ec"
};
