import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams, useHistory } from "react-router";
import { resetPasswordStart } from "../../store/actions/UserAction";
import { connect } from "react-redux";

const ResetPasswordIndex = (props) => {
  const params = useParams();
  const history = useHistory();
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Required")
      .test('len', 'password length should be minimum 6', val => val && val.length >= 6),
    password_confirmation: Yup.string().required("Required").when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      )
    }),
  });

  const reset = (values) => {
    props.dispatch(resetPasswordStart({
      ...values,
      reset_token: params.token,
    }));
  }

  useEffect(() => {
    if (!skipRender && !props.resetPassword.loading && props.resetPassword.data.success) {
      history.push("/login");
    }
    setSkipRender(false);
  }, [props.resetPassword]);

  return (
    <>
      <div className="new-login-sec">
        <Container>
          <div className="login-form-sec">
            <Row className="align-items-center">
              <Col md={6}>
                <div className="login-img">
                  <Image
                    src={
                      window.location.origin + "/assets/images/login-img.jpg"
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="login-form">
                  <div className="new-login-img">
                    <Image
                      className="mb-3"
                      src={
                        window.location.origin +
                        "/assets/images/logo-metaocean.png"
                      }
                    />
                  </div>
                  <h2>Reset Password</h2>
                  <Formik
                    initialValues={{
                      password: "",
                      password_confirmation: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => reset(values)}
                  >
                    {({ errors, touched }) => (
                      <FORM>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>New Password</Form.Label>
                          <Field
                            className="form-control"
                            type="password"
                            placeholder="Enter Your New Password"
                            name="password"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="text-danger small"
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Confirm Password</Form.Label>
                          <Field
                            className="form-control"
                            type="password"
                            placeholder="Confirm Password"
                            name="password_confirmation"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="password_confirmation"
                            className="text-danger small"
                          />
                        </Form.Group>
                        <div className="new-login-btn mb-3">
                          <Button
                            className="default-btn border-radius-5"
                            type="submit"
                            disabled={props.resetPassword.buttonDisable}
                          >
                            {props.resetPassword.loadingButtonContent ?
                              props.resetPassword.loadingButtonContent
                              : "Submit"
                            }
                          </Button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  resetPassword: state.users.resetPassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ResetPasswordIndex);
