import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { Modal, Image, Table, Media } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { clearNftBuyData } from "../../store/actions/NftAction";
import { authContext } from "../authprovider/AuthProvider";
import usePriceImage from "../priceImages/PriceImage";
import { useTheme } from "@material-ui/core";
import CustomSelect from "../../customComponents/CustomSelect";
import configuration from "react-global-configuration";
import CustomCalander from "../../customComponents/CustomCalander";
import moment from "moment";
import { addDays } from "date-fns";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const MakeOfferModal = (props) => {
  const [priceSelect, setPriceSelect] = useState([]);

  const [minBid, setMinBid] = useState(0);

  const { makeOfferButtonContent } = props;

  const { auth, price } = useContext(authContext);

  const theme = useTheme();

  const priceImage = usePriceImage(theme.palette.theme);

  const nativeCurrencySymbol = configuration.get(
    "configData.native_currency_symbol"
  )
    ? configuration.get("configData.native_currency_symbol")
    : "VLX";

  const makeOfferSchema = Yup.object().shape({
    amount: Yup.number()
      .min()
      .required("* Amount is required.")
      .test(
        "min",
        `${
          minBid == 0
            ? "* amount must be grater than 0."
            : `* amount must be grater than last bid ${minBid} ${nativeCurrencySymbol}.`
        }`,
        (value) => Number(value) > minBid
      ),
  });

  const currentdate = new Date();

  const [makeOfferStatus, setMakeOfferStatus] = useState(
    props.singleNftImage.data.nft_collection_item.buyer_unique_id ==
      auth.userUniqueId
      ? "listed"
      : "unreviewed"
  );

  const constructEndTimeFormat = () => {
    let timeFormat = "";

    if (currentdate.getHours() + 1 > 23) {
      timeFormat += `${
        currentdate.getHours() < 10
          ? `0${currentdate.getHours()}`
          : currentdate.getHours()
      }:${
        currentdate.getMinutes() < 10
          ? `0${currentdate.getMinutes()}`
          : currentdate.getMinutes()
      }`;
    } else {
      timeFormat += `${
        currentdate.getHours() + 1 < 10
          ? `0${currentdate.getHours() + 1}`
          : currentdate.getHours() + 1
      }:${
        currentdate.getMinutes() < 10
          ? `0${currentdate.getMinutes()}`
          : currentdate.getMinutes()
      }`;
    }

    return timeFormat;
  };

  const [offerData, setOfferData] = useState({
    amount: "0",
    bid_expire_at:
      currentdate.getHours() + 1 > 23
        ? moment(addDays(new Date(), 1)).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
    endTime: constructEndTimeFormat(),
    isEndTimeError: false,
    sameDay: currentdate.getHours() + 1 > 23 ? false : true,
  });

  const [termsAgreed, setTermsAgreed] = useState(false);

  const handlePriceSelect = (event) => {
    const {
      target: { value },
    } = event;
    setPriceSelect(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleDateSelect = useCallback(
    (value) => {
      console.log(value);
      setOfferData({
        ...offerData,
        bid_expire_at: value,
        sameDay: new Date() > value,
        endTime:
          new Date() > value
            ? constructEndTimeFormat()
            : `${
                currentdate.getHours() < 10
                  ? `0${currentdate.getHours()}`
                  : currentdate.getHours()
              }:${
                currentdate.getMinutes() < 10
                  ? `0${currentdate.getMinutes()}`
                  : currentdate.getMinutes()
              }`,
      });
    },
    [offerData]
  );

  const handleEndTimeChange = useCallback((value) => {
    if (value == "") {
      setOfferData({
        ...offerData,
        isEndTimeError: true,
        endTime: value,
      });
    } else {
      setOfferData({
        ...offerData,
        endTime: value,
      });
    }
  });

  useEffect(() => {
    if (
      !props.offersave.loading &&
      props.offersave.data.code == 124 &&
      props.singleNftImage.data.nft_collection_item
    ) {
      setMakeOfferStatus("listed");
    }
    props.setMakeOfferButtonContent("");
  }, [props.offersave.data]);

  const closeMakeOffer = async () => {
    setMakeOfferStatus("unreviewed");
    props.handleMakeOfferClose();
    props.dispatch(clearNftBuyData());
    // props.getCurrentOwner()
  };

  useEffect(() => {
    const endTime =
      offerData.endTime != "" ? String(offerData.endTime).split(":") : "";
    const endHour = offerData.endTime != "" ? endTime[0].replace(/^0/, "") : "";
    const endMinute =
      offerData.endTime != "" ? endTime[1].replace(/^0/, "") : "";

    if (offerData.sameDay) {
      if (Number(endHour) < currentdate.getHours()) {
        setOfferData({
          ...offerData,
          isEndTimeError: true,
        });
      } else if (Number(endHour) == currentdate.getHours()) {
        setOfferData({
          ...offerData,
          isEndTimeError: true,
        });
      } else if (Number(endHour) > currentdate.getHours()) {
        setOfferData({
          ...offerData,
          isEndTimeError: false,
        });
      }
    } else {
      setOfferData({
        ...offerData,
        isEndTimeError: false,
      });
    }
  }, [offerData.endTime, offerData.sameDay]);

  useEffect(() => {
    if (
      !props.bidOfferNftItemList.loading &&
      props.bidOfferNftItemList.data.nft_item_offers &&
      props.bidOfferNftItemList.data.nft_item_offers.length > 0
    ) {
      setMinBid(
        props.bidOfferNftItemList.data.nft_item_offers[0].bid_status != 3 ? 
        Number(props.bidOfferNftItemList.data.nft_item_offers[0].amount) : 0
      );
      setOfferData({
        ...offerData,
        amount: props.bidOfferNftItemList.data.nft_item_offers[0].bid_status != 3 ? props.bidOfferNftItemList.data.nft_item_offers[0].amount : "0",
      });
    }
  }, [props.bidOfferNftItemList.data]);

  const handleSubmit = () => {
    props.makeOfferSave(offerData);
  };

  const handleTermsChange = () => {
    setTermsAgreed((prev) => !prev);
  };

  return (
    <>
      {props.singleNftImage.data.nft_collection ? (
        <Modal
          show={props.open}
          onHide={props.handleMakeOfferClose}
          animation={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          className={
            makeOfferStatus
              ? "custom-modal complete-checkout-modal"
              : "custom-modal unreviewed-modal"
          }
          centered
        >
          {makeOfferStatus == "unreviewed" && (
            <>
              <Formik
                initialValues={{
                  amount: offerData.amount,
                }}
                className="w-100"
                validationSchema={makeOfferSchema}
                onSubmit={(values) => handleSubmit(values)}
                enableReinitialize
              >
                {({ errors, touched, setFieldValue, values, isSubmitting }) => (
                  <Form>
                    <Modal.Header>
                      <div className="modal-header-wrapper">
                        <h3 className="mb-0 modal-heading-text">
                          Make an offer
                        </h3>
                        <div
                          className="custom-close"
                          onClick={() => props.handleMakeOfferClose()}
                        >
                          <CloseIcon />
                        </div>
                      </div>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        <div className="mb-3">
                          <p>
                            Your offer will be shown to the item owner , offers
                            can be listed for maximum 10 days.
                          </p>
                        </div>
                        <h3 className="mb-0 modal-heading-text mb-1">Price</h3>
                        <div className="listing-item-form">
                          <CustomSelect
                            value={priceSelect}
                            handleChange={handlePriceSelect}
                            items={[props.singleNftImage.data.nft_collection_item.blockchain_type]}
                            placeHolder={props.singleNftImage.data.nft_collection_item.blockchain_type}
                            defaultValue={props.singleNftImage.data.nft_collection_item.blockchain_type}
                          />

                          <div>
                            <Field
                              type="number"
                              name="amount"
                              placeholder="Amount"
                              className={`form-control ${
                                touched.name && errors.name ? "is-invalid" : ""
                              }`}
                              value={offerData.amount}
                              onChange={(event) =>
                                setOfferData({
                                  ...offerData,
                                  amount: event.target.value,
                                })
                              }
                            />
                            {offerData.amount > 0 &&
                              price.usdConvertedPrice != null && (
                                <p className="converted_price right">
                                  ≉{" "}
                                  <i
                                    className="fa fa-usd"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {Number(
                                    offerData.amount * price.usdConvertedPrice
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })}{" "}
                                  {price.denomination}
                                </p>
                              )}
                          </div>
                        </div>

                        <h3 className="mb-0 modal-heading-text mb-1 text-center">
                          Offer Expiration
                        </h3>
                        <div className="offer-date">
                          <CustomCalander
                            months={1}
                            value={offerData.bid_expire_at}
                            handleDateSelect={handleDateSelect}
                            handleEndTimeChange={handleEndTimeChange}
                            withTime
                            useTimeValue
                            timeValue={offerData.endTime}
                            isTimeError={offerData.isEndTimeError}
                          />
                        </div>
                        <div className="custom-checkbox w-100 mt-3">
                          <div class="check-field">
                            <input
                              id="my-check"
                              type="checkbox"
                              checked={termsAgreed}
                              onChange={() => handleTermsChange()}
                            />
                            <label for="my-check">
                              I agree to the following{" "}
                              <Link to="/page/terms" target="_blank">terms & conditions</Link>
                            </label>
                          </div>
                        </div>
                        {offerData.isEndTimeError && (
                          <p className="invalid-message-text mt-3">
                            * End time must be atleast 1 hour from current time
                          </p>
                        )}
                        <ErrorMessage
                          component="div"
                          name="amount"
                          className="invalid-feedback mt-3"
                        />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="default-btn border-radius-5"
                        disabled={
                          makeOfferButtonContent != ""
                            ? true
                            : false || offerData.isEndTimeError || !termsAgreed
                        }
                        type="submit"
                        // onClick={() => props.makeOfferSave(offerData)}
                      >
                        {makeOfferButtonContent != ""
                          ? makeOfferButtonContent
                          : "Make Offer"}
                      </button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </>
          )}

          {makeOfferStatus == "listed" && (
            <>
              <Modal.Header>
                <div className="modal-header-wrapper">
                  <h3 className="mb-0 modal-heading-text">
                    Your offer is completed successfully
                  </h3>
                  <div
                    className="custom-close"
                    onClick={() => props.handleMakeOfferClose()}
                  >
                    <CloseIcon />
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="item-listed-sale-sec">
                  <Image
                    src={props.singleNftImage.data.nft_collection_item.picture}
                    alt="Image"
                    className="user-profile-img"
                  />
                  <div className="item-listed-sale-status-sec">
                    <div className="item-listed-sale-card">
                      <h6>Links</h6>
                    </div>
                    <div className="item-listed-sale-card">
                      <ul className="item-listed-sale-share-sec list-unstyled">
                        {props.singleNftImage.data.nft_collection
                          .twitter_link ? (
                          <Media as="li">
                            <a
                              href={
                                props.singleNftImage.data.nft_collection
                                  .twitter_link
                              }
                              target="_blank"
                            >
                              <i className="fab fa-twitter"></i>
                            </a>
                          </Media>
                        ) : null}

                        {props.singleNftImage.data.nft_collection
                          .facebook_link ? (
                          <Media as="li">
                            <a
                              href={
                                props.singleNftImage.data.nft_collection
                                  .facebook_link
                              }
                              target="_blank"
                            >
                              <i className="fab fa-facebook"></i>
                            </a>
                          </Media>
                        ) : null}

                        {props.singleNftImage.data.nft_collection
                          .telegram_link ? (
                          <Media as="li">
                            <a
                              href={
                                props.singleNftImage.data.nft_collection
                                  .telegram_link
                              }
                              target="_blank"
                            >
                              <i className="fas fa-paper-plane"></i>
                            </a>
                          </Media>
                        ) : null}

                        {props.singleNftImage.data.nft_collection_item
                          .external_link ? (
                          <Media as="li">
                            <a
                              href={
                                props.singleNftImage.data.nft_collection_item
                                  .external_link
                              }
                              target="_blank"
                            >
                              <i className="fas fa-link"></i>
                            </a>
                          </Media>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="default-btn border-radius-5"
                  onClick={() => closeMakeOffer()}
                >
                  View Item
                </button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  offersave: state.nfts.bidOfferNftItemSave,
  bidOfferNftItemList: state.nfts.bidOfferNftItemList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MakeOfferModal);
