import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  InputGroup,
  FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";
// import "./Verification.css";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { getSuccessNotificationMessage } from "../helper/ToastNotification";
import CopyToClipboard from 'react-copy-to-clipboard';

const PrivateKeyIndex = (props) => {
  const history = useHistory();
  const [key, setKey] = useState("");

  useEffect(() => {
    if ((Object.keys(props.verifyEmail.data).length > 0 && props.verifyEmail.data.private_passphrase) ||
      (Object.keys(props.registerInputData.data).length > 0 && props.registerInputData.data.data.private_passphrase)) {
      setKey(Object.keys(props.verifyEmail.data).length > 0 ?
        props.verifyEmail.data.private_passphrase
        : props.registerInputData.data.data.private_passphrase)
    } else {
      history.push("/");
    }
  }, []);

  const onCopy = () => {
    props.dispatch(getSuccessNotificationMessage("Private key copied"));
  }

  return (
    <>{key !== "" ?
      <div className="verification-sec">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8} xl={5} lg={6}>
              <div className="verification-box">
                <div className="verification-logo-sec mb-4">
                  <Link to="/">
                    <Image
                      className="verification-logo"
                      src={
                        configuration.get("configData.site_logo") ?
                          configuration.get("configData.site_logo")
                          : window.location.origin + "/assets/images/logo-metaocean.png"
                      }
                    />
                  </Link>
                </div>
                <p>
                  <b>Warning:</b> Never disclose this key.
                  Anyone with your private keys can steal any assets held in your account.
                  Kindly store it in a secure location.
                </p>
                <div className="verification-item">
                  <div className="private-key-container">
                    {key}
                    <div className="private-key-copy">
                      <CopyToClipboard text={key}
                        onCopy={() => onCopy()}>
                        <Image
                          className="copy-icon"
                          src={
                            window.location.origin + "/assets/images/copy.svg"
                          }
                        />
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
                <div className="auth-btn-sec verification-btn-sec justify-content-center">
                  <Link
                    to="/"
                    className="default-btn border-radius-5"
                  >
                    Continue
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  verifyEmail: state.users.verifyEmail,
  registerInputData: state.users.registerInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(PrivateKeyIndex);
