import React, { useState, useEffect } from 'react';
import {
  Button,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getSuccessNotificationMessage } from '../../helper/ToastNotification';
import { cancelWithdrawalRequestStart } from '../../../store/actions/WalletAction';

const WalletWithdrawTableRow = (props) => {
  const { withdraw } = props;

  const cancelWithdraw = () => {
    props.dispatch(cancelWithdrawalRequestStart({
      withdrawal_unique_id: withdraw.withdrawal_unique_id,
    }));
  };

  const onCopy = () => {
    props.dispatch(getSuccessNotificationMessage("Transacction Id Copied"))
  }

  return (
    <tr>
      <td>{withdraw.created_formatted}</td>
      <td className="text-center">
        {withdraw.payment_id ?
          <OverlayTrigger placement="top" overlay={<Tooltip>{withdraw.payment_id}</Tooltip>}>
            <div className="transaction-id-sec" title={withdraw.payment_id}>
              <CopyToClipboard text={withdraw.payment_id}
                onCopy={() => onCopy()}>
                <span>{withdraw.payment_id}</span>
              </CopyToClipboard>
            </div>
          </OverlayTrigger>
          : "-"}
      </td>
      <td>{withdraw.user_billing_account_name}</td>
      <td>{withdraw.requested_amount_formatted}</td>
      <td>{withdraw.paid_amount_formatted}</td>
      <td>{withdraw.status_formatted}</td>
      <td className="text-center">
        {withdraw.status === 0 ?
          <Button
            variant="danger"
            onClick={e => cancelWithdraw()}
            disabled={props.cancelWithdrawRequest.buttonDisable &&
              props.cancelWithdrawRequest.data.withdrawal_unique_id === withdraw.withdrawal_unique_id}
          >
            {props.cancelWithdrawRequest.data.withdrawal_unique_id === withdraw.withdrawal_unique_id &&
              props.cancelWithdrawRequest.loadingButtonContent ?
              props.cancelWithdrawRequest.loadingButtonContent :
              "Cancel"
            }
          </Button>
          : "-"
        }
        {/* <Button className="cancel-btn">Cancel</Button> */}
      </td>
    </tr>
  );
}

const mapStateToPros = (state) => ({
  cancelWithdrawRequest: state.wallet.cancelWithdrawRequest,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WalletWithdrawTableRow);