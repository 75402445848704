import { useEffect, useState } from "react";

const useUserAuthStatus = (profileData) => {

	const [userAuthStatus, setUserAuthStatus] = useState({
		userLoggedIn: false,
		userId: 0,
		userUniqueId: "",
		wallet_address: "",
	});

	useEffect(() => {
		if (profileData == undefined || profileData.loading || Object.keys(profileData.data).length <= 0 ||
			!localStorage.getItem("userId") || !localStorage.getItem("accessToken") ||
			localStorage.getItem("userId") == null || !localStorage.getItem("accessToken") == null ||
			localStorage.getItem("userId") == undefined || !localStorage.getItem("accessToken") == undefined) {
			setUserAuthStatus({
				...userAuthStatus,
				userLoggedIn: false,
				userId: 0,
				userUniqueId: "",
			});
		}
		else if(!profileData.loading && Object.keys(profileData.data).length > 0){
			setUserAuthStatus({
				...userAuthStatus,
				userLoggedIn: true,
				userId: profileData.data.user.user_id,
				userUniqueId: profileData.data.user.user_unique_id,
				wallet_address: profileData.data.user.wallet_address,
			});
		}
	}, [profileData]);

	return userAuthStatus;
};

export default useUserAuthStatus;
