import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Modal, Image, Table } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import {
  cancelListNftItemStart,
  fetchSingleNftItemsStart,
} from "../../store/actions/NftAction";
import CustomAccordion from "../../customComponents/CustomAccordion";
import { authContext } from "../authprovider/AuthProvider";
import configuration from "react-global-configuration";
import { updateSignAndApproveStart } from "../../store/actions/UserAction";

const CancelListingModal = (props) => {
  const { auth, library, getProviderSigner } = useContext(authContext);

  const [confirmCancelList, setConfirmCancelList] = useState(false);

  const [listCancelled, setListCancelled] = useState(false);

  const [accordionData, setAccordionData] = useState(null);

  useEffect(() => {
    if (
      !props.cancelListNftItem.loading &&
      props.cancelListNftItem.data.code == 120
    ) {
      setListCancelled(true);
    }
  }, [props.cancelListNftItem.data]);

  useEffect(() => {
    if (listCancelled) {
      props.handleCancelListingClose();
      props.dispatch(
        fetchSingleNftItemsStart({
          nft_collection_unique_id:
            props.singleNftImage.data.nft_collection.nft_collection_unique_id,
          nft_collection_item_token_id:
            props.singleNftImage.data.nft_collection_item.token_id,
        })
      );
    }
  }, [listCancelled]);

  const getSign = async () => {
    props.setSignData({
      ...props.signData,
      loading: true,
      loadingContent: "Signing...",
    });
    if (auth.authStatus) {
      const txMessage = `
        Welcome to ${configuration.get("configData.site_name")}!
  
        Click to sign in and accept the ${configuration.get(
          "configData.site_name"
        )} Terms of Service: ${configuration.get("configData.frontend_url")}
  
        This request will not trigger a blockchain transaction or cost any gas fees.
  
        Your authentication status will reset after 24 hours.
  
        Wallet address:
        ${auth.accounts}`;
      let result = await getProviderSigner(txMessage);
      if (result) {
        props.dispatch(
          updateSignAndApproveStart({
            is_signed: 1,
            sign_signature: result,
          })
        );

        props.setSignData({
          ...props.signData,
          signature: result,
          status: true,
          loading: false,
          loadingContent: null,
        });

        props.toggleAccordion("2");
      } else {
        props.setSignData({
          ...props.signData,
          loading: false,
          loadingContent: null,
        });
      }
    } else {
      props.setSignData({
        ...props.signData,
        loading: false,
        loadingContent: null,
      });
    }
  };

  useEffect(() => {
    setAccordionData({
      activeAccordion: props.activeAccordion,
      accordions: [
        {
          name: "sign",
          heading: "Sign message",
          accordionContent:
            "Submit a transaction with your wallet to trade with this currency. This only needs to be done once.",
          showAccordionButton: true,
          onClickFunction: () => getSign(),
          accordionButtonDisable:
            props.signData.loading || props.signData.loadingContent != null,
          accordionButtonContent:
            props.signData.loadingContent != null
              ? props.signData.loadingContent
              : "Sign",
          spinner: true,
          spinnerLoading: props.signData.loadingContent != null,
          isVisible:
            props.singleNftImage.data.nft_collection_item.is_user_signed == 0,
        },
        {
          name: "cancel",
          heading: "Cancel lisiting",
          accordionContent: "Your lisiting will be canceled.",
          showAccordionButton: true,
          onClickFunction: () => props.cancelBlockchain(),
          accordionButtonDisable:
            props.cancelListNftItem.buttonDisable ||
            props.loadingButtonContent != "",
          accordionButtonContent:
            props.cancelListNftItem.loadingButtonContent != "" ||
            props.loadingButtonContent != ""
              ? "Canceling Please wait..."
              : "Confirm",
          spinner: true,
          spinnerLoading: props.loadingButtonContent != "",
          isVisible: true,
        },
      ],
    });
  }, [
    props.loadingButtonContent,
    props.activeAccordion,
    props.signData.loadingContent,
    props.cancelListNftItem
  ]);

  return (
    <>
      <Modal
        show={props.open}
        onHide={props.handleCancelListingClose}
        animation={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="custom-modal cancel-listing-modal"
        centered
      >
        {confirmCancelList ? (
          <>
            <Modal.Header>
              <div className="modal-header-wrapper">
                <i
                  className="fas fa-arrow-left"
                  onClick={() => setConfirmCancelList(false)}
                ></i>
                <h3 className="mb-0 modal-heading-text">Cancel your listing</h3>
                <div
                  className="custom-close"
                  onClick={() => props.handleCancelListingClose()}
                >
                  <CloseIcon />
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="cancel-order-listing-sec">
                {accordionData != null &&
                  accordionData.accordions
                    .filter((accordions) => accordions.isVisible == true)
                    .map((accordion, index) => (
                      <>
                        <CustomAccordion
                          activeId={accordionData.activeAccordion}
                          accordionHeading={accordion.heading}
                          accordionKey={String(index + 1)}
                          accordionContent={accordion.accordionContent}
                          accordionButton={true}
                          OnClick={() => accordion.onClickFunction()}
                          accordionButtonDisable={
                            accordion.accordionButtonDisable
                          }
                          accordionButtonContent={
                            accordion.accordionButtonContent
                          }
                          spinner
                          spinnerLoading={
                            accordion.spinnerLoading &&
                            props.activeAccordion === String(index + 1)
                          }
                          activeAccordion={accordionData.activeAccordion}
                          loadingComplete={
                            Number(accordionData.activeAccordion) > index + 1
                          }
                        />
                      </>
                    ))}
              </div>
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Header>
              <div className="modal-header-wrapper">
                <h3 className="mb-0 modal-heading-text">
                  Are you sure you want to cancel your listing
                </h3>
                <div
                  className="custom-close"
                  onClick={() => props.handleCancelListingClose()}
                >
                  <CloseIcon />
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="cancel-listing-btn-sec">
                <button
                  className="default-btn border-radius-5"
                  onClick={() => setConfirmCancelList(true)}
                >
                  Confirm
                </button>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  cancelListNftItem: state.nfts.cancelListNftItem,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(React.memo(CancelListingModal));
