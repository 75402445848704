import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Modal, Image, Table, Media } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import {
  buyNftItemStart,
  fetchSingleNftItemsStart,
  clearNftBuyData,
} from "../../store/actions/NftAction";
import { authContext } from "../authprovider/AuthProvider";
import usePriceImage, { stableTokenLight } from "../priceImages/PriceImage";
import { useTheme } from "@material-ui/core";
import configuration from "react-global-configuration";
import CustomSelect from "../../customComponents/CustomSelect";
import { CHAIN_INFO, SupportedChainId, SupportedChainIdBySymbol } from "../authprovider/chains";

const UnReviewedModal = (props) => {
  const { buttonContent, sendMoneyToCreator } = props;

  const { auth, price } = useContext(authContext);

  const theme = useTheme();

  const priceImage = usePriceImage(theme.palette.theme);

  const [buyItemStatus, setBuyItemStatus] = useState(
    props.singleNftImage.data.nft_collection_item.buyer_unique_id ==
      auth.userUniqueId
      ? "listed"
      : "unreviewed"
  );

  useEffect(() => {
    if (
      !props.buyNftItem.loading &&
      props.buyNftItem.data.code == 121 &&
      props.singleNftImage.data.nft_collection_item
    ) {
      setBuyItemStatus("listed");
      props.dispatch(
        fetchSingleNftItemsStart({
          nft_collection_unique_id:
            props.singleNftImage.data.nft_collection.nft_collection_unique_id,
          nft_collection_item_token_id:
            props.singleNftImage.data.nft_collection_item.token_id,
        })
      );
      props.dispatch(clearNftBuyData());
    }
  }, [props.buyNftItem.data]);

  const handlePriceSelect = (event) => {
    const {
      target: { value },
    } = event;
    props.setPriceSelect(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      {props.singleNftImage.data.nft_collection ? (
        <Modal
          show={props.open}
          onHide={props.handleUnReviewedClose}
          animation={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          className={
            buyItemStatus
              ? "custom-modal complete-checkout-modal"
              : "custom-modal unreviewed-modal"
          }
          centered
        >
          {buyItemStatus == "unreviewed" && (
            <>
              <Modal.Header>
                <div className="modal-header-wrapper">
                  <h3 className="mb-0 modal-heading-text">Buy Nft Image</h3>
                  <div
                    className="custom-close"
                    onClick={() => props.handleUnReviewedClose()}
                  >
                    <CloseIcon />
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="unreviewed-sec">
                  <div className="unreviewed-img-sec">
                    <Image
                      src={window.location.origin + `/assets/images/search.png`}
                      alt="Image"
                      className="unreviewed-icon-img"
                    />
                  </div>
                  <p>
                    Review this information to ensure it's what you want to buy.
                  </p>
                </div>
                <div className="unreviewed-info-sec">
                  <div className="unreviewed-table-sec">
                    <div className="unreviewed-table-card">
                      <p>Collection name</p>
                      <Link
                        to={`/collection/${props.singleNftImage.data.nft_collection.nft_collection_unique_id}`}
                      >
                        {props.singleNftImage.data.nft_collection.name}
                      </Link>
                    </div>
                    <div className="unreviewed-table-card">
                      <p>item name</p>
                      <p className="clr-black">
                        {props.singleNftImage.data.nft_collection_item.name}
                      </p>
                    </div>
                    <div className="unreviewed-table-card last">
                      <p>Creator</p>
                      <Link
                        to={`/account/${props.singleNftImage.data.nft_collection_item.user_unique_id}`}
                      >
                        {
                          props.singleNftImage.data.nft_collection_item
                            .user_wallet_address
                        }
                      </Link>
                    </div>
                    {/* <div className="unreviewed-table-card">
                      <p>Total sales</p>
                      <p className="clr-black">5 sales</p>
                    </div> */}
                    <div className="item-listed-sale-status-sec mt-3">
                      <div className="item-listed-sale-card">
                        <h6>Links</h6>
                      </div>
                      <div className="item-listed-sale-card">
                        <ul className="item-listed-sale-share-sec list-unstyled">
                          {props.singleNftImage.data.nft_collection
                            .twitter_link ? (
                            <Media as="li">
                              <a
                                href={
                                  props.singleNftImage.data.nft_collection
                                    .twitter_link
                                }
                                target="_blank"
                              >
                                <i className="fab fa-twitter"></i>
                              </a>
                            </Media>
                          ) : null}

                          {props.singleNftImage.data.nft_collection
                            .facebook_link ? (
                            <Media as="li">
                              <a
                                href={
                                  props.singleNftImage.data.nft_collection
                                    .facebook_link
                                }
                                target="_blank"
                              >
                                <i className="fab fa-facebook"></i>
                              </a>
                            </Media>
                          ) : null}

                          {props.singleNftImage.data.nft_collection
                            .telegram_link ? (
                            <Media as="li">
                              <a
                                href={
                                  props.singleNftImage.data.nft_collection
                                    .telegram_link
                                }
                                target="_blank"
                              >
                                <i className="fas fa-paper-plane"></i>
                              </a>
                            </Media>
                          ) : null}

                          {props.singleNftImage.data.nft_collection_item
                            .external_link ? (
                            <Media as="li">
                              <a
                                href={
                                  props.singleNftImage.data.nft_collection_item
                                    .external_link
                                }
                                target="_blank"
                              >
                                <i className="fas fa-link"></i>
                              </a>
                            </Media>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {/* <div className="checkbox-method-area m-0">
                  <div className="checkbox-method">
                    <p>
                      <input
                        type="radio"
                        id="fixed-price"
                        name="radio-group"
                        onChange={() => setBuyItemStatus("checkout")}
                      />
                      <label for="fixed-price" className="mb-0">
                        I understand that nft marketplace has not reviewed this
                        collection and blockchain transaction are irreversible
                      </label>
                    </p>
                  </div>
                </div> */}
                <div className="custom-checkbox w-100">
                  <div className="check-field">
                    <input
                      id="my-check"
                      type="checkbox"
                      onChange={() => setBuyItemStatus("checkout")}
                    />
                    <label for="my-check">
                      I agree to the following{" "}
                      <Link to="/page/terms" target="_blank">terms & conditions</Link>
                    </label>
                  </div>
                </div>
              </Modal.Footer>
            </>
          )}

          {buyItemStatus == "checkout" && (
            <>
              <Modal.Header>
                <div className="modal-header-wrapper">
                  <i
                    className="fas fa-arrow-left"
                    onClick={() => setBuyItemStatus("unreviewed")}
                  ></i>
                  <h3 className="mb-0 modal-heading-text">Complete Purchase</h3>
                  <div
                    className="custom-close"
                    onClick={() => props.handleUnReviewedClose()}
                  >
                    <CloseIcon />
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="complete-checkout-sec">
                  <div className="complete-checkout-header">
                    <h4>Purchase Details</h4>
                    {/* <h4>Subtotal</h4> */}
                  </div>
                  <div className="complete-checkout-body">
                    <div className="complete-checkout-card">
                      <div className="complete-checkout-img-sec">
                        <Image
                          src={
                            props.singleNftImage.data.nft_collection_item
                              .picture
                          }
                          alt="Image"
                          className="complete-checkout-img"
                        />
                        <div className="complete-checkout-info">
                          <div className="verified-icon-sec">
                            <Link to="#">
                              {props.singleNftImage.data.nft_collection.name}
                            </Link>
                            {props.singleNftImage.data.nft_collection.is_verified == 1 && (
                              <img
                                className="verified-icon"
                                src={window.location.origin + "/assets/images/icons/verified.png"}
                                alt="Verified Icon" />
                            )}
                          </div>
                          <h4>
                            {props.singleNftImage.data.nft_collection_item.name}
                          </h4>
                        </div>
                      </div>
                      <div className="complete-checkout-amount-sec">
                        <h4>

                          <>
                            <Image
                              src={CHAIN_INFO[SupportedChainIdBySymbol[props.singleNftImage.data.nft_collection.blockchain_type]].logo}
                              alt="Image"
                              className="velas-logo"
                            />
                            {props.singleNftImage.data.nft_collection_item.amount_formatted}
                          </>
                        </h4>

                        <div className="d-flex">
                          {props.singleNftImage.data.nft_collection_item
                            .amount > 0 &&
                            price.usdConvertedPrice != null && (
                              <p className="converted_price right">
                                ≉{" "}
                                <i className="fa fa-usd" aria-hidden="true"></i>
                                {Number(
                                  props.singleNftImage.data.nft_collection_item
                                    .amount * price.usdConvertedPrice
                                ).toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                })}{" "}
                                {price.denomination}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                    {/* <div>
                      <CustomSelect
                        value={props.priceSelect}
                        handleChange={handlePriceSelect}
                        items={[
                          configuration.get(
                            "configData.native_currency_symbol"
                          ),
                          configuration.get("configData.stable_toke_symbol"),
                        ]}
                        placeHolder={configuration.get(
                          "configData.native_currency_symbol"
                        )}
                        defaultValue={configuration.get(
                          "configData.native_currency_symbol"
                        )}
                      />
                    </div> */}
                    {/* <div className="complete-checkout-card">
                      <h4>Total</h4>
                      <div className="complete-checkout-amount-sec">
                        <h4>
                          <Image
                            src={window.location.origin + `/assets/images/velas-logo.svg`}
                            alt="Image"
                            className="velas-logo"
                          />
                          <span>{props.singleNftImage.data.nft_collection_item.amount}</span>
                        </h4>
                        <p>${props.priceFormatted * Number(props.singleNftImage.data.nft_collection_item.amount)} USD</p>
                      </div>
                    </div> */}
                    {/* <div className="complete-checkout-radio">
                      <div className="checkbox-method-area m-0">
                        <div className="checkbox-method">
                          <p>
                            <input type="radio" id="fixed-price" name="radio-group" />
                            <label for="fixed-price" className="mb-0">By checking this box, I agree to Marketplace Terms of Service.</label>
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="default-btn border-radius-5"
                  disabled={
                    props.singleNftImage.data.nft_collection_item.is_sold ==
                      1 || buttonContent != ""
                      ? true
                      : false
                  }
                  onClick={() => sendMoneyToCreator()}
                >
                  {buttonContent != "" ? buttonContent : "Buy now"}
                </button>
              </Modal.Footer>
            </>
          )}

          {buyItemStatus == "listed" && (
            <>
              <Modal.Header>
                <div className="modal-header-wrapper">
                  <h3 className="mb-0 modal-heading-text">
                    You have bought the item
                  </h3>
                  <div
                    className="custom-close"
                    onClick={() => props.handleUnReviewedClose()}
                  >
                    <CloseIcon />
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="item-listed-sale-sec">
                  <Image
                    src={props.singleNftImage.data.nft_collection_item.picture}
                    alt="Image"
                    className="user-profile-img"
                  />
                  <div className="item-listed-sale-status-sec">
                    <div className="item-listed-sale-card">
                      <h6>Status</h6>
                    </div>
                    <div className="item-listed-sale-card">
                      <ul className="item-listed-sale-share-sec list-unstyled">
                        {props.singleNftImage.data.nft_collection
                          .twitter_link ? (
                          <Media as="li">
                            <a
                              href={
                                props.singleNftImage.data.nft_collection
                                  .twitter_link
                              }
                              target="_blank"
                            >
                              <i className="fab fa-twitter"></i>
                            </a>
                          </Media>
                        ) : null}

                        {props.singleNftImage.data.nft_collection
                          .facebook_link ? (
                          <Media as="li">
                            <a
                              href={
                                props.singleNftImage.data.nft_collection
                                  .facebook_link
                              }
                              target="_blank"
                            >
                              <i className="fab fa-facebook"></i>
                            </a>
                          </Media>
                        ) : null}

                        {props.singleNftImage.data.nft_collection
                          .telegram_link ? (
                          <Media as="li">
                            <a
                              href={
                                props.singleNftImage.data.nft_collection
                                  .telegram_link
                              }
                              target="_blank"
                            >
                              <i className="fas fa-paper-plane"></i>
                            </a>
                          </Media>
                        ) : null}

                        {props.singleNftImage.data.nft_collection_item
                          .external_link ? (
                          <Media as="li">
                            <a
                              href={
                                props.singleNftImage.data.nft_collection_item
                                  .external_link
                              }
                              target="_blank"
                            >
                              <i className="fas fa-link"></i>
                            </a>
                          </Media>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="default-btn border-radius-5"
                  onClick={() => props.handleUnReviewedClose()}
                >
                  View Item
                </button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  buyNftItem: state.nfts.buyNftItem,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(UnReviewedModal);
