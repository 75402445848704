import React, { useState } from "react";
// import { ToastContainer } from "react-toastify";
import EmptyFooter from "./Footer/EmptyFooter";
import EmptyHeader from "./Header/EmptyHeader";

const EmptyLayout = (props) => {

  return (
    <>
      <EmptyHeader/>
      {/* <ToastContainer /> */}
      {props.children}
      <EmptyFooter />
    </>
  );
};

export default EmptyLayout;
