import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FETCH_USER_DETAILS_START,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS_START,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  FETCH_OTHER_USER_DETAILS_START,
  FETCH_OTHER_USER_DETAILS_SUCCESS,
  FETCH_OTHER_USER_DETAILS_FAILURE,
  UPDATE_SIGN_APPROVE_START,
  UPDATE_SIGN_APPROVE_SUCCESS,
  UPDATE_SIGN_APPROVE_FAILURE,
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  RESEND_EMAIL_VERIFICATION_CODE_START,
  RESEND_EMAIL_VERIFICATION_CODE_SUCCESS,
  RESEND_EMAIL_VERIFICATION_CODE_FAILURE,
  TWO_STEP_AUTH_LOGIN_START,
  TWO_STEP_AUTH_LOGIN_SUCCESS,
  TWO_STEP_AUTH_LOGIN_FAILURE,
  TWO_STEP_AUTH_RESEND_CODE_START,
  TWO_STEP_AUTH_RESEND_CODE_SUCCESS,
  TWO_STEP_AUTH_RESEND_CODE_FAILURE,
  UPDATE_TWO_STEP_AUTH_START,
  UPDATE_TWO_STEP_AUTH_SUCCESS,
  UPDATE_TWO_STEP_AUTH_FAILURE,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  profile: {
    data: {},
    loading: false,
    error: false,
  },
  otherProfile: {
    data: {},
    loading: true,
    error: false,
  },
  loginInputData: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  registerInputData: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  updateUserDetails: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false
  },
  updateSignApprove: {
    data: {},
    loading: false,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false
  },
  forgotPassword: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  resetPassword: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  verifyEmail: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  resendVerificationCode: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  twoStepAuthLogin: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  resendTwoStepAuthCode: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  updateTwoStepAuth: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  changePassword: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        loginInputData: {
          data: {
            ...action.data,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          loading: true,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        },
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data,
          loading: false,
          error: false,
        },
        loginInputData: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loginInputData: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case REGISTER_START:
      return {
        ...state,
        registerInputData: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registerInputData: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        registerInputData: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };

    case FETCH_USER_DETAILS_START:
      return {
        ...state,
        profile: {
          data: {},
          loading: true,
          error: false,
        },
        updateUserDetails: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false
        }
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        profile: {
          data: {},
          loading: false,
          error: true,
        },
      };
    case UPDATE_USER_DETAILS_START:
      return {
        ...state,
        updateUserDetails: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Updating...",
          buttonDisable: true
        },
      };
    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        updateUserDetails: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false
        },
      };
    case UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        updateUserDetails: {
          data: {},
          loading: true,
          error: action.error,
          loadingButtonContent: "",
          buttonDisable: false
        },
      };
    case FETCH_OTHER_USER_DETAILS_START:
      return {
        ...state,
        otherProfile: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_OTHER_USER_DETAILS_SUCCESS:
      return {
        ...state,
        otherProfile: {
          data: action.data.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_OTHER_USER_DETAILS_FAILURE:
      return {
        ...state,
        otherProfile: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_SIGN_APPROVE_START:
      return {
        ...state,
        updateSignApprove: {
          ...state.updateSignApprove,
          data: {},
          loading: true,
          error: false,
        },
      };
    case UPDATE_SIGN_APPROVE_SUCCESS:
      return {
        ...state,
        updateSignApprove: {
          ...state.updateSignApprove,
          data: action.data.data,
          loading: false,
          error: false,
        },
      };
    case UPDATE_SIGN_APPROVE_FAILURE:
      return {
        ...state,
        updateSignApprove: {
          ...state.updateSignApprove,
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FORGOT_PASSWORD_START:
      return {
        ...state,
        forgotPassword: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        },
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPassword: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case RESET_PASSWORD_START:
      return {
        ...state,
        resetPassword: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          data: action.data,
          loading: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPassword: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case VERIFY_EMAIL_START:
      return {
        ...state,
        verifyEmail: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyEmail: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        verifyEmail: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case RESEND_EMAIL_VERIFICATION_CODE_START:
      return {
        ...state,
        resendVerificationCode: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case RESEND_EMAIL_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        resendVerificationCode: {
          data: action.data,
          loading: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case RESEND_EMAIL_VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        resendVerificationCode: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case TWO_STEP_AUTH_LOGIN_START:
      return {
        ...state,
        twoStepAuthLogin: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case TWO_STEP_AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        twoStepAuthLogin: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case TWO_STEP_AUTH_LOGIN_FAILURE:
      return {
        ...state,
        twoStepAuthLogin: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case TWO_STEP_AUTH_RESEND_CODE_START:
      return {
        ...state,
        resendTwoStepAuthCode: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case TWO_STEP_AUTH_RESEND_CODE_SUCCESS:
      return {
        ...state,
        resendTwoStepAuthCode: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case TWO_STEP_AUTH_RESEND_CODE_FAILURE:
      return {
        ...state,
        resendTwoStepAuthCode: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case UPDATE_TWO_STEP_AUTH_START:
      return {
        ...state,
        updateTwoStepAuth: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case UPDATE_TWO_STEP_AUTH_SUCCESS:
      return {
        ...state,
        updateTwoStepAuth: {
          data: action.data,
          loading: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case UPDATE_TWO_STEP_AUTH_FAILURE:
      return {
        ...state,
        updateTwoStepAuth: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case CHANGE_PASSWORD_START:
      return {
        ...state,
        changePassword: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePassword: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    default:
      return state;
  }
};

export default userReducer;
