import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { apiConstants } from "../Constant/constants";
import configuration from "react-global-configuration";
import { useHistory } from "react-router";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { userLoginStart } from '../../store/actions/UserAction';
import { connect } from "react-redux";

const LoginIndex = (props) => {
  const history = useHistory();

  const [forgotPassword, setForgotPassword] = useState(false);

  const closeForgotPasswordModal = () => {
    setForgotPassword(false);
  };

  const [skipRender, setSkipRender] = useState(true);
  const [loginInputData, setLoginInputData] = useState({
    login_by: apiConstants.LOGIN_BY,
    device_token: apiConstants.DEVICE_TYPE,
    device_type: apiConstants.DEVICE_TOKEN,
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const login = (values) => {
    props.dispatch(userLoginStart({ ...values, ...loginInputData }));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.loginInputData.loading &&
      Object.keys(props.loginInputData.data).length > 0
    ) {
      if (props.loginInputData.data.user.is_two_step_auth_enabled == 1) {
        history.push("/verification");
      } else if (props.loginInputData.data.user.is_email_verified == 0) {
        history.push("/register-verification");
      } else {
        history.push("/");
      }
    }
    setSkipRender(false);
  }, [props.loginInputData]);

  return (
    <>
      <div className="new-login-sec">
        <Container>
          <div className="login-form-sec">
            <Row className="align-items-center justify-content-center">
              <Col md={5}>
                <div className="login-img">
                  <Image
                    src={
                      window.location.origin + "/assets/images/login-img.jpg"
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="login-form">
                  <div className="new-login-img">
                    <Link to="/">
                      <Image
                        src={
                          configuration.get("configData.site_logo") ?
                            configuration.get("configData.site_logo")
                            : window.location.origin + "/assets/images/logo-metaocean.png"
                        }
                      />
                    </Link>
                  </div>
                  {/* <h2>Log In</h2> */}
                  <Formik
                    initialValues={{
                      email: configuration.get("configData.demo_user_email") ?
                        configuration.get("configData.demo_user_email") : "",
                      password: configuration.get("configData.demo_user_password") ?
                        configuration.get("configData.demo_user_password") : "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => login(values)}
                  >
                    {({ errors, touched }) => (
                      <FORM>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email address</Form.Label>
                          <Field
                            className="form-control"
                            type="email"
                            placeholder="Enter Your Email Address"
                            name="email"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="text-danger small"
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Password</Form.Label>
                          <Field
                            className="form-control"
                            type="password"
                            placeholder="Enter Your Password"
                            name="password"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="text-danger small"
                          />
                        </Form.Group>
                        <div className="forgot-password-link mb-3">
                          <Link to="#" onClick={() => setForgotPassword(true)}>
                            Forgot Password ?
                          </Link>
                        </div>
                        <div className="new-login-btn mb-3">
                          <Button
                            className="default-btn border-radius-5"
                            type="submit"
                            disabled={props.loginInputData.buttonDisable}
                          >
                            {props.loginInputData.loadingButtonContent ?
                              props.loginInputData.loadingButtonContent
                              : "Login"
                            }
                          </Button>
                        </div>
                        <div className="register-link text-center d-flex justify-content-center">
                          <p>Create a new account</p>
                          <Link to="/register">Click Here</Link>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {forgotPassword ?
        <ForgotPasswordModal
          forgotPassword={forgotPassword}
          closeForgotPasswordModal={closeForgotPasswordModal}
          setForgotPassword={setForgotPassword}
        />
        : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  loginInputData: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(LoginIndex);
