import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage, FieldArray } from "formik";
import "./Authentication.css";
import Switch from '@material-ui/core/Switch';
import * as Yup from "yup";
import { changePasswordStart, updateTwoStepAuthStart } from '../../store/actions/UserAction';
import { connect } from "react-redux";
import { useHistory } from "react-router";

const AuthenticationPanel = (props) => {
  const history = useHistory();
  const [skipRender, setSkipRender] = useState(true);
  const [initialTwoStep, setInitialTwoStep] = useState(false);
  const [twoStepAuth, setTwoStepAuth] = useState(false);

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required("Required"),
    password: Yup.string().required("Required").when("old_password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().notOneOf(
        [Yup.ref("old_password")],
        "Should not be old password"
      )
    }),
    password_confirmation: Yup.string().required("Required").when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      )
    }),
  });

  useEffect(() => {
    if (!skipRender && !props.changePassword.loading && Object.keys(props.changePassword.data).length > 0) {
      setTimeout(() => {
        history.push("/logout");
      }, 2000);
    }
    setSkipRender(false);
  }, [props.changePassword]);

  useEffect(() => {
    if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
      setInitialTwoStep(props.profile.data.user.is_two_step_auth_enabled === 0 ? false : true);
      setTwoStepAuth(props.profile.data.user.is_two_step_auth_enabled === 0 ? false : true);
    }
  }, [props.profile]);

  const changePassword = values => {
    props.dispatch(changePasswordStart(values));
  }

  const changeTwoStepAuth = values => {
    props.dispatch(updateTwoStepAuthStart(values));
  }


  return (
    <>
      <div className="authentication-wrapper">
        <div className="section-title">
          <h2 className="mb-3">Security</h2>
        </div>
        <div className="col-lg-12 col-md-12">
          <div class="form-group border-section">
            <div className="two-factor-auth-card">
              <h5 className="profile-heading">Two-factor authetication</h5>
              <Switch
                checked={twoStepAuth}
                onClick={() => setTwoStepAuth(!twoStepAuth)}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </div>
          {initialTwoStep != twoStepAuth ?
            <Formik
              initialValues={{
                password: "",
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string().required("Required"),
              })}
              onSubmit={(values) => changeTwoStepAuth(values)}
            >
              {({ errors, touched }) => (
                <FORM className="collection-form">
                  <div className="col-lg-12 col-md-12">
                    <div class="form-group ">
                      <h6>
                        Current Password
                      </h6>
                      <div class="controls">
                        <Field
                          type="password"
                          className="form-control"
                          placeholder="Enter Your Current Password"
                          name="password"
                        />
                        <ErrorMessage component={"div"} name="password" className='text-danger text-right' />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 create-btn">
                    <button
                      type="submit"
                      className="default-btn border-radius-5 px-5 py-3 mt-4"
                      disable={props.updateTwoStepAuth.buttonDiable}
                    >
                      {props.updateTwoStepAuth.loadingButtonContent ?
                        props.updateTwoStepAuth.loadingButtonContent
                        : "Submit"
                      }
                    </button>
                  </div>
                </FORM>
              )}
            </Formik>
            : null
          }
        </div>
        <h5 className="profile-heading border-section">Change Passsword</h5>
        <Formik
          initialValues={{
            old_password: "",
            password: "",
            password_confirmation: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => changePassword(values)}
        >
          {({ errors, touched }) => (
            <FORM className="collection-form">
              <div className="col-lg-12 col-md-12">
                <div class="form-group ">
                  <h6>
                    Current Password
                  </h6>
                  <div class="controls">
                    <Field
                      type="password"
                      className="form-control"
                      placeholder="Enter Your Current Password"
                      name="old_password"
                    />
                    <ErrorMessage component={"div"} name="old_password" className='text-danger text-right' />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div class="form-group ">
                  <h6>
                    New Password
                  </h6>
                  <div class="controls">
                    <Field
                      type="password"
                      className="form-control"
                      placeholder="Enter Your New Password"
                      name="password"
                    />
                    <ErrorMessage component={"div"} name="password" className='text-danger text-right' />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div class="form-group ">
                  <h6>
                    Confirm Password
                  </h6>
                  <div class="controls">
                    <Field
                      type="password"
                      className="form-control"
                      placeholder="Confirm Your New Password"
                      name="password_confirmation"
                    />
                    <ErrorMessage component={"div"} name="password_confirmation" className='text-danger text-right' />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 create-btn">
                <button
                  type="submit"
                  className="default-btn border-radius-5 px-5 py-3 mt-4"
                  disable={props.changePassword.buttonDiable}
                >
                  {props.changePassword.loadingButtonContent ?
                    props.changePassword.loadingButtonContent
                    : "Submit"
                  }
                </button>
              </div>
            </FORM>
          )}
        </Formik>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  changePassword: state.users.changePassword,
  profile: state.users.profile,
  updateTwoStepAuth: state.users.updateTwoStepAuth,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AuthenticationPanel);
