import React, { createContext, useState, useMemo, useEffect } from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

export const themeContext = createContext({});

const ThemeChanger = (props) => {
  const { theme, themetoggler } = props;

  const activetheme = useMemo(
    () =>
      createTheme({
        palette: {
          theme,
          type: theme,
          // primary: {
          //   ...(theme === "dark" && {
          //     main: "#303339",
          //   }),
          // },
          ...(theme === "dark" && {
            background: {
              default: "#0c0c0c",
              paper: "#0c0c0c",
            },
          }),
          text: {
            ...(theme === "light"
              ? {
                  primary: "#0c0c0c",
                  secondary: "rgba(4, 17, 29, 0.5)",
                }
              : {
                  primary: "#fff",
                  secondary: "#ffffffbf",
                }),
          },
          border: {
            ...(theme === "light"
              ? {
                  primary: "rgb(229, 232, 235)",
                  secondary: "rgba(4, 17, 29, 0.15)",
                }
              : {
                  primary: "rgb(21, 27, 34)",
                  secondary: "#303339",
                }),
          },
        },
        loader: {
          ...(theme === "light"
            ? {
                primary: "#ebebeb",
                secondary: "#f5f5f5",
              }
            : {
                primary: "rgb(38, 43, 47)",
                secondary: "#303339",
              }),
        },
        themeColor: {
          primary: "#00C75E",
          secondry: "#8d99ff",
        },
      }),
    [theme]
  );

  // useEffect(() => {
  //   localStorage.setItem("theme", theme);
  //   if (theme === "dark") {
  //     document.body.classList.add("dark-mode");
  //   } else {
  //     document.body.classList.remove("dark-mode");
  //   }
  // }, [theme]);

  return (
    <themeContext.Provider
      value={{
        theme,
        themetoggler,
      }}
    >
      <ThemeProvider theme={activetheme}>{props.children}</ThemeProvider>
    </themeContext.Provider>
  );
};

export default React.memo(ThemeChanger);
