import React, { useEffect } from "react";
import api from "../../Environment";
import { useHistory } from "react-router";

const Logout = (props) => {

	const history = useHistory();

	useEffect(() => {
		api.postMethod("logout").then((response) => {
			if (response.data.success) {
				console.log("success");
			} else {
			}
		});
		localStorage.removeItem("userId");
		localStorage.removeItem("accessToken");
		localStorage.removeItem("userLoginStatus");
		localStorage.removeItem("user_picture");
		localStorage.removeItem("user_cover");
		localStorage.removeItem("name");
		localStorage.removeItem("username");
		localStorage.removeItem("user_unique_id");
		localStorage.removeItem("email");
		// window.location.assign("/");
		history.push('/');
	}, []);
	return "";
};

export default Logout;
