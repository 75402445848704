import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  setDefaultUserCardStart,
  fetchUserCardListStart,
  deleteUserCardStart
} from '../../../store/actions/WalletAction';

const SingleCard = (props) => {
  const { card, selectedCard, setSelectedCard } = props;
  const [skipRender, setSkipRender] = useState(true);

  const makeDefaultCard = e => {
    props.dispatch(setDefaultUserCardStart({
      user_card_id: card.user_card_id,
    }));
    e.stopPropagation();
  };

  const removeCard = e => {
    props.dispatch(deleteUserCardStart({
      user_card_id: card.user_card_id,
    }));
    e.stopPropagation();
  }

  useEffect(() => {
    if (!skipRender && !props.defaultUserCard.loading && Object.keys(props.defaultUserCard.data).length > 0) {
      props.dispatch(fetchUserCardListStart());
    }
    setSkipRender(false);
  }, [props.defaultUserCard]);

  return (
    <div className="select-card">
      <input type="radio" name="card" checked={selectedCard === card.user_card_id} />
      <label onClick={() => setSelectedCard(card.user_card_id)}>
        <div className="select-item">
          <div className="select-item-icon-sec">
            <Image
              className="select-item-icon"
              src={
                card.card_type === "visa" ? window.location.origin + "/assets/images/wallet/visa.png" :
                  card.card_type === "mastercard" ? window.location.origin + "/assets/images/wallet/master.png" :
                    window.location.origin + "/assets/images/wallet/visa.png"
              }
            />
          </div>
          <div className="select-item-info">
            <h6>{card.card_holder_name}..{card.last_four}</h6>
            {card.is_default === 1 ?
              <p className="text-success">Default</p>
              : <p className="text-warning" onClick={makeDefaultCard}>
                {props.defaultUserCard.loadingButtonContent &&
                  props.defaultUserCard.data.user_card_id === card.user_card_id ?
                  props.defaultUserCard.loadingButtonContent
                  : "Make as default"
                }
              </p>}
            <span onClick={removeCard} className="text-danger card-delete">
              {props.deleteUserCard.loadingButtonContent &&
                props.deleteUserCard.data.user_card_id === card.user_card_id ?
                props.deleteUserCard.loadingButtonContent
                : <img src={window.location.origin + "/assets/images/wallet/delete.svg"} width="25" height="25" />
              }
            </span>
          </div>
        </div>
      </label>
    </div>
  )
}

const mapStateToPros = (state) => ({
  defaultUserCard: state.wallet.defaultUserCard,
  deleteUserCard: state.wallet.deleteUserCard,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(SingleCard);