import React, { useState, useEffect } from 'react';
import AddNewBillingAccountModal from "./AddNewBillingAccountModal";
import {
  Container,
  Row, Col,
  Image, Button, Tab, Table, Form, InputGroup
} from "react-bootstrap";
import SettingsSidebar from '../SettingsSidebar';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import configuration from "react-global-configuration";
import {
  fetchUserBillingAccountsListStart,
  withdrawalRequestStart
} from '../../../store/actions/WalletAction';
import { getErrorNotificationMessage } from '../../helper/ToastNotification';
import { connect } from 'react-redux';
import SingleAccount from './SingleAccount';
import CommonCenterLoader from '../../helper/CommonCenterLoader';

const WithDrawMoneyIndex = (props) => {
  const history = useHistory();

  const [addNewBillingAccount, setAddNewBillingAccount] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [amount, setAmount] = useState("");

  const closeAddNewBillingAccountModal = () => {
    setAddNewBillingAccount(false);
  };

  useEffect(() => {
    props.dispatch(fetchUserBillingAccountsListStart());
  }, []);

  useEffect(() => {
    if (!skipRender && !props.addUserBillingAccounts.loading && Object.keys(props.addUserBillingAccounts.data).length > 0) {
      closeAddNewBillingAccountModal();
      props.dispatch(fetchUserBillingAccountsListStart());
    }
  }, [props.addUserBillingAccounts]);

  useEffect(() => {
    if (!skipRender && !props.userBillingAccounts.loading && Object.keys(props.userBillingAccounts.data).length > 0) {
      if (props.userBillingAccounts.data.user_billing_accounts && props.userBillingAccounts.data.user_billing_accounts.length > 0) {
        props.userBillingAccounts.data.user_billing_accounts.map((account, i) => {
          if (account.is_default === 1)
            setSelectedAccount(account.user_billing_account_id);
        })
      } else {
        setSelectedAccount(null);
      }
    }
  }, [props.userBillingAccounts]);

  const withdrawMoney = e => {
    e.preventDefault();
    if (amount && selectedAccount) {
      props.dispatch(withdrawalRequestStart({
        amount: amount,
        user_billing_account_id: selectedAccount,
      }));
    } else if (!amount) {
      props.dispatch(getErrorNotificationMessage("Please enter the amount"));
    } else if (!selectedAccount) {
      props.dispatch(getErrorNotificationMessage("Please add or select an account"));
    }
  }

  useEffect(() => {
    if (!skipRender && !props.withdrawRequest.loading && Object.keys(props.withdrawRequest.data).length > 0) {
      history.push("/withdraw");
    }
    setSkipRender(false);
  }, [props.withdrawRequest]);

  return (
    <>
      <div className="profile-setting pages-padding">
        <div className="profile-setting-wrapper">
          <Row className="mt-3 mt-md-0 g-0">
            <SettingsSidebar />
            <Col md={8} lg={9} className="mt-3 mt-md-0">
              <div className="withdraw-wrapper">
                <div className="step-2">
                  <div className="add-money-sec">
                    <div className="section-title">
                      <h3 className="mb-3">Withdraw Money</h3>
                    </div>
                    <Form>
                      <div className="add-money-header-sec">
                        <div className="add-money-form-card">
                          <InputGroup className="mb-0">
                            <Form.Control
                              placeholder="Enter Amount"
                              value={amount}
                              onChange={e => setAmount(e.target.value)}
                            />
                            <InputGroup.Text id="basic-addon2">$</InputGroup.Text>
                          </InputGroup>
                        </div>
                        <div className="add-money-withdraw-btn-sec">
                          <div className="add-new-billing-balance-sec">
                            Balance <span>{
                              !props.userBillingAccounts.loading && props.userBillingAccounts.data.user_wallet ?
                                props.userBillingAccounts.data.user_wallet.remaining_formatted
                                : "0 " + configuration.get("configData.currency")
                            }</span>
                          </div>
                          <Button className="withdraw-btn border-radius-5 px-4 py-2" type="submit" onClick={withdrawMoney}>
                            Withdraw
                          </Button>
                        </div>
                      </div>
                      <div className="select-card-sec">
                        <h4>Choose Billing Account</h4>
                        <div className="select-card-box">
                          {props.userBillingAccounts.loading ?
                            <CommonCenterLoader />
                            : <>
                              {props.userBillingAccounts.data.user_billing_accounts &&
                                props.userBillingAccounts.data.user_billing_accounts.map((account, i) =>
                                  <SingleAccount
                                    account={account}
                                    selectedAccount={selectedAccount}
                                    setSelectedAccount={setSelectedAccount}
                                  />
                                )}
                              <div className="add-card" onClick={() => setAddNewBillingAccount(true)}>
                                <Link to="#" >
                                  <div className="select-item">
                                    <div className="select-item-icon-sec">
                                      <Image src={window.location.origin + "/assets/images/wallet/plus-add.svg"} className="add-icon" />
                                    </div>
                                    <div className="select-item-info">
                                      <h6>Add New Billing Account</h6>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {addNewBillingAccount ?
        <AddNewBillingAccountModal
          addNewBillingAccount={addNewBillingAccount}
          closeAddNewBillingAccountModal={closeAddNewBillingAccountModal}
        />
        : null}
    </>
  );
}

const mapStateToPros = (state) => ({
  userBillingAccounts: state.wallet.userBillingAccounts,
  addUserBillingAccounts: state.wallet.addUserBillingAccounts,
  withdrawRequest: state.wallet.withdrawRequest,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WithDrawMoneyIndex);