import React, { useState, useEffect } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { forgotPasswordStart } from "../../store/actions/UserAction";
import { connect } from "react-redux";

const ForgotPasswordModal = (props) => {
  const [step, setStep] = useState(1);

  const [skipRender, setSkipRender] = useState(true);

  const [email, setEmail] = useState("");

  useEffect(() => {
    if (!skipRender &&
      !props.forgotPassword.loading &&
      Object.keys(props.forgotPassword.data).length > 0) {
      setStep(2)
    }
    setSkipRender(false);
  }, [props.forgotPassword]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const handleForgotPassword = (values) => {
    setEmail(values.email);
    props.dispatch(forgotPasswordStart(values));
  }

  return (
    <>
      <Modal
        className="modal-dialog-center add-card-modal custom-modal"
        size="md"
        centered
        show={props.forgotPassword}
        onHide={props.closeForgotPasswordModal}
      >
        <Modal.Body>
          <h4 className="forgot-password-title">Forgot Password</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeForgotPasswordModal()}
          >
            <Image
              className="close-icon"
              src={window.location.origin + "/assets/images/modal-close.svg"}
            />
          </Button>
          {step === 1 &&
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => handleForgotPassword(values)}
            >
              {({ errors, touched }) => (
                <FORM>
                  <div className="forgot-password-login-form">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Field
                        className="form-control"
                        type="email"
                        placeholder="Enter Your Email Address"
                        name="email"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="email"
                        className="text-danger small"
                      />
                    </Form.Group>
                    <div className="new-login-btn mb-3">
                      <Button
                        className="default-btn border-radius-5"
                        type="submit"
                        disabled={props.forgotPassword.buttonDisable}
                      >
                        {props.forgotPassword.loadingButtonContent ?
                          props.forgotPassword.loadingButtonContent
                          : "Submit"
                        }
                      </Button>
                    </div>
                  </div>
                </FORM>
              )}
            </Formik>
          }
          {step == 2 && (
            <div className="check-your-mail-card">
              <div className="check-your-mail-icon-sec">
                <Image
                  className="wave-hand-icon"
                  src={
                    window.location.origin + "/assets/images/email-icon.png"
                  }
                />
              </div>
              <h5>Chek Your Mail</h5>
              <p>We emailed a reset password link to <span>{email}</span> click the link to reset your password</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  forgotPassword: state.users.forgotPassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ForgotPasswordModal);
