import { hexStripZeros } from "@ethersproject/bytes";
import { Web3Provider } from "@ethersproject/providers";
import { SELECTED_CHAIN_INFO, SUPPORTED_RPC_URLS } from "./chains";
import { BigNumber } from "@ethersproject/bignumber";

export const changeNetwork = async (chainId) => {
  console.log("change network", chainId);
  const formattedChainId = hexStripZeros(BigNumber.from(Number(chainId)).toHexString());

  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: formattedChainId }],
    });
  } catch (error) {
    // 4902 is the error code for attempting to switch to an unrecognized chainId
    if (error.code === 4902) {
      const info = SELECTED_CHAIN_INFO(chainId);

			console.log('chainId', chainId);
			console.log('info', info);
			console.log('formattedChainId', formattedChainId);
			console.log('SUPPORTED_RPC_URLS rpcUrls', SUPPORTED_RPC_URLS[chainId]);
      // await window.ethereumlibrary.provider.request({
      //   method: "wallet_addEthereumChain",
      //   // params: [
      //   //   {
      //   //     chainId: formattedChainId,
      //   //     chainName: info.label,
      //   //     rpcUrls: SUPPORTED_RPC_URLS[chainId],
      //   //     nativeCurrency: info.nativeCurrency,
      //   //     blockExplorerUrls: [info.explorer],
      //   //   },
			// 		params: [
      //       {
      //         //https://data-seed-prebsc-1-s1.binance.org:8545/
      //         chainId: formattedChainId,
      //         rpcUrls: SUPPORTED_RPC_URLS[chainId],
      //         chainName: info.label,
      //         nativeCurrency: info.nativeCurrency,
      //         blockExplorerUrls: [info.explorer],
      //       },
      //   ],
      // });
			try {
				await window.ethereum.request({
					method: 'wallet_addEthereumChain',
					params: [
						{
							chainId: formattedChainId,
							chainName: info.label,
							nativeCurrency: {
								name: info.nativeCurrency.name,
								symbol: info.nativeCurrency.symbol, // 2-6 characters long
								decimals: info.nativeCurrency.decimals
							},
							blockExplorerUrls: [info.explorer],
							rpcUrls: [SUPPORTED_RPC_URLS[chainId]],
						},
					],
				})
				.catch((e) => {
					throw e.message;
        });
			} catch (addError) {
				console.error("addError", addError);
			}
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: formattedChainId }],
        });
      } catch (error) {
        console.debug("Added network but could not switch chains", error);
      }
    } else {
      throw error;
    }
  }
};
