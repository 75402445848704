import React, { useState, useEffect } from 'react';
import SettingsSidebar from '../SettingsSidebar';
import {
  Col,
  Tab,
  Row,
  Table,
  Form,
  InputGroup,
  Image,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import AddCardModal from './AddCardModal';
import { useHistory } from 'react-router';
import { fetchUserCardListStart, walletPaymentByStripeStart } from '../../../store/actions/WalletAction';
import { getErrorNotificationMessage } from '../../helper/ToastNotification';
import { connect } from 'react-redux';
import CommonCenterLoader from '../../helper/CommonCenterLoader';
import SingleCard from './SingleCard';

const AddMoneyIndex = (props) => {
  const history = useHistory();

  const [addCard, setAddCard] = useState(false);
  const [skipFirst, setSkipFirst] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);
  const [amount, setAmount] = useState("");

  const closeAddCardModal = () => {
    setAddCard(false);
  }

  useEffect(() => {
    props.dispatch(fetchUserCardListStart());
  }, []);

  useEffect(() => {
    if (!skipFirst && !props.userCardList.loading && Object.keys(props.userCardList.data).length > 0) {
      if (props.userCardList.data.user_cards && props.userCardList.data.user_cards.length > 0) {
        props.userCardList.data.user_cards.map((card, i) => {
          if (card.is_default === 1)
            setSelectedCard(card.user_card_id);
        })
      } else {
        setSelectedCard(null);
      }
    }
  }, [props.userCardList]);

  useEffect(() => {
    if (!skipFirst && !props.addUserCard.loading && Object.keys(props.addUserCard.data).length > 0) {
      closeAddCardModal();
      props.dispatch(fetchUserCardListStart());
    }
  }, [props.addUserCard]);

  const addMoney = e => {
    e.preventDefault();
    if (amount && selectedCard) {
      props.dispatch(walletPaymentByStripeStart({
        amount: amount,
        card_id: selectedCard,
      }));
    } else if (!amount) {
      props.dispatch(getErrorNotificationMessage("Please enter the amount"));
    } else if (!selectedCard) {
      props.dispatch(getErrorNotificationMessage("Please add or select a card"));
    }
  }

  useEffect(() => {
    if (!skipFirst && !props.walletPaymentByStripe.loading && Object.keys(props.walletPaymentByStripe.data).length > 0) {
      history.push("/wallet");
    }
    setSkipFirst(false);
  }, [props.walletPaymentByStripe]);

  return (
    <>
      <div className="profile-setting pages-padding">
        <div className="profile-setting-wrapper">
          <Row className="mt-3 mt-md-0 g-0">
            <SettingsSidebar />
            <Col md={8} lg={9} className="mt-3 mt-md-0">
              <div className="wallet-wrapper">
                <div className="step-2">
                  <div className="add-money-sec">
                    <div className="section-title">
                      <h3 className="mb-3">Add Money</h3>
                    </div>
                    <Form>
                      <div className="add-money-header-sec">
                        <div className="add-money-form-card">
                          <InputGroup className="mb-0">
                            <Form.Control
                              placeholder="Enter Amount"
                              value={amount}
                              onChange={e => setAmount(e.target.value)}
                            />
                            <InputGroup.Text id="basic-addon2">$</InputGroup.Text>
                          </InputGroup>
                        </div>
                        <div className="add-money-btn-sec">
                          <Button
                            type="submit"
                            className="default-btn two border-radius-5 px-4 py-2"
                            onClick={addMoney}
                            disabled={props.walletPaymentByStripe.buttonDisable}
                          >
                            {props.walletPaymentByStripe.loadingButtonContent ?
                              props.walletPaymentByStripe.loadingButtonContent
                              : "Add Money"
                            }
                          </Button>
                        </div>
                      </div>
                      <div className="select-card-sec">
                        <h4>Select Card</h4>
                        <div className="select-card-box">
                          {props.userCardList.loading ?
                            <CommonCenterLoader />
                            :
                            <>{props.userCardList.data.user_cards &&
                              props.userCardList.data.user_cards.map((card, i) =>
                                <SingleCard
                                  card={card}
                                  key={i}
                                  selectedCard={selectedCard}
                                  setSelectedCard={setSelectedCard}
                                />
                              )}

                              <div className="add-card" onClick={() => setAddCard(true)}>
                                <div className="select-item">
                                  <div className="select-item-icon-sec">
                                    <Image src={window.location.origin + "/assets/images/wallet/plus-add.svg"} className="add-icon" />
                                  </div>
                                  <div className="select-item-info">
                                    <h6>Add Card</h6>
                                  </div>
                                </div>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {addCard ?
        <AddCardModal
          addCard={addCard}
          closeAddCardModal={closeAddCardModal}
          setAddCard={setAddCard}
        />
        : null
      }
    </>
  );
}

const mapStateToPros = (state) => ({
  addUserCard: state.wallet.addUserCard,
  userCardList: state.wallet.userCardList,
  walletPaymentByStripe: state.wallet.walletPaymentByStripe,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(AddMoneyIndex);