import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Modal, Image, Accordion, Card, Media } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { updateSignAndApproveStart } from "../../store/actions/UserAction";
import { useHistory } from "react-router";
import usePriceImage, {
  networkLogo,
} from "../../components/priceImages/PriceImage";
import CustomAccordion from "../../customComponents/CustomAccordion";
import { authContext } from "../authprovider/AuthProvider";
import { useTheme } from "@material-ui/core";
import configuration from "react-global-configuration";

const CompleteListingModal = (props) => {
  const chainName = configuration.get("configData.native_currency_name")
    ? configuration.get("configData.native_currency_name")
    : "collection";

  const history = useHistory();

  const theme = useTheme();

  const priceImage = usePriceImage(theme.palette.theme);

  const { auth, library, getProviderSigner, price } = useContext(authContext);

  const handleApprove = () => {
    props.makeOfferToSale();
  };

  useEffect(() => {
    if (!props.sellNftItem.loading && props.sellNftItem.data.code == 119) {
      props.setItemListed(true);
    }
  }, [props.sellNftItem.data]);

  const [accordionData, setAccordionData] = useState(null);

  const getSign = async () => {

    props.setSignData({
      ...props.signData,
      loading: true,
      loadingContent: "Signing...",
    });
    if (props.userAuthStatus.userLoggedIn) {
      const txMessage = `
      Welcome to ${configuration.get("configData.site_name")}!
  
      Click to sign in and accept the ${configuration.get(
        "configData.site_name"
      )} Terms of Service: ${configuration.get("configData.frontend_url")}
  
        This request will not trigger a blockchain transaction or cost any gas fees.
  
        Your authentication status will reset after 24 hours.
  
        Wallet address:
        ${auth.accounts}`;
      let result = await getProviderSigner(txMessage);
      if (result) {
        props.dispatch(
          updateSignAndApproveStart({
            is_signed: 1,
            sign_signature: result,
          })
        );

        props.setSignData({
          ...props.signData,
          signature: result,
          status: true,
          loading: false,
          loadingContent: null,
        });
        props.toggleAccordion((prev) => String(Number(prev) + 1));
      } else {
        props.setSignData({
          ...props.signData,
          loading: false,
          loadingContent: null,
        });
      }
    } else {
      props.setSignData({
        ...props.signData,
        loading: false,
        loadingContent: null,
      });
    }
  };

  useEffect(() => {
    setAccordionData({
      activeAccordion: props.activeAccordion,
      accordions: [
        {
          name: "sign",
          heading: "Sign message",
          accordionContent:
            "Submit a transaction with your wallet to trade with this currency. This only needs to be done once.",
          showAccordionButton: true,
          onClickFunction: () => getSign(),
          accordionButtonDisable:
            props.updateSignApprove.loading ||
            props.signData.loadingContent != null,
          accordionButtonContent:
            props.updateSignApprove.loadingButtonContent != ""
              ? props.signData.loadingContent
              : "Sign",
          spinner: true,
          spinnerLoading: props.signData.loadingContent != null,
          isVisible:
            props.singleNftImage.data.nft_collection_item.is_user_signed == 0,
        },
        {
          name: "approve",
          heading: "Approve",
          accordionContent: "Approve Transaction.",
          showAccordionButton: true,
          onClickFunction: props.getApprovalToSale,
          accordionButtonDisable:
            props.sellNftItem.buttonDisable || props.loadingButtonContent != "",
          accordionButtonContent:
            props.sellNftItem.loadingButtonContent != "" ||
              props.loadingButtonContent != ""
              ? "Approving..."
              : "Approve",
          spinner: true,
          spinnerLoading: props.loadingButtonContent != "",
          isVisible:
            props.singleNftImage.data.nft_collection_item
              .user_approved_status == 0,
        },
        {
          name: "offer",
          heading: "Complete Listing",
          accordionContent: "Complete Listing to sell the NFT.",
          showAccordionButton: true,
          onClickFunction: () => handleApprove(),
          accordionButtonDisable:
            props.sellNftItem.buttonDisable || props.loadingButtonContent != "",
          accordionButtonContent:
            props.sellNftItem.loadingButtonContent != "" ||
              props.loadingButtonContent != ""
              ? "Listing Please wait..."
              : "Complete Listing",
          spinner: true,
          spinnerLoading: props.loadingButtonContent != "",
          isVisible: true,
        },
      ],
    });
  }, [
    props.loadingButtonContent,
    props.activeAccordion,
    props.signData.loadingContent,
    props.sellNftItem,
  ]);

  return (
    <>
      <Modal
        show={props.open}
        animation={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className={
          props.itemListed
            ? "custom-modal item-listed-sale-modal"
            : "custom-modal complete-listing"
        }
        centered
      >
        {props.itemListed ? (
          props.singleNftImage.data.nft_collection_item && (
            <>
              <Modal.Header>
                <div className="modal-header-wrapper">
                  <h3 className="mb-0 modal-heading-text">
                    Your item is now listed for sale
                  </h3>
                  {/* <div className="custom-close" onClick={() => props.handleItemListedSaleClose()}>
                  <CloseIcon />
                </div> */}
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="item-listed-sale-sec">
                  <Image
                    src={
                      props.singleNftImage.data.nft_collection_item.picture !=
                        ""
                        ? props.singleNftImage.data.nft_collection_item.picture
                        : props.singleNftImage.data.nft_collection_item.picture
                    }
                    alt="Image"
                    className="user-profile-img"
                  />
                  {props.singleNftImage.data.nft_collection.twitter_link !=
                    "" ||
                    props.singleNftImage.data.nft_collection.facebook_link !=
                    "" ||
                    props.singleNftImage.data.nft_collection.telegram_link !=
                    "" ||
                    props.singleNftImage.data.nft_collection.external_link !=
                    "" || (
                      <>
                        <div className="item-listed-sale-status-sec">
                          <div className="item-listed-sale-card">
                            <h6>Links</h6>
                          </div>
                          <div className="item-listed-sale-card">
                            <ul className="item-listed-sale-share-sec list-unstyled">
                              {props.singleNftImage.data.nft_collection
                                .twitter_link ? (
                                <Media as="li">
                                  <a
                                    href={
                                      props.singleNftImage.data.nft_collection
                                        .twitter_link
                                    }
                                    target="_blank"
                                  >
                                    <i className="fab fa-twitter"></i>
                                  </a>
                                </Media>
                              ) : null}

                              {props.singleNftImage.data.nft_collection
                                .facebook_link ? (
                                <Media as="li">
                                  <a
                                    href={
                                      props.singleNftImage.data.nft_collection
                                        .facebook_link
                                    }
                                    target="_blank"
                                  >
                                    <i className="fab fa-facebook"></i>
                                  </a>
                                </Media>
                              ) : null}

                              {props.singleNftImage.data.nft_collection
                                .telegram_link ? (
                                <Media as="li">
                                  <a
                                    href={
                                      props.singleNftImage.data.nft_collection
                                        .telegram_link
                                    }
                                    target="_blank"
                                  >
                                    <i className="fas fa-paper-plane"></i>
                                  </a>
                                </Media>
                              ) : null}

                              {props.singleNftImage.data.nft_collection_item
                                .external_link ? (
                                <Media as="li">
                                  <a
                                    href={
                                      props.singleNftImage.data
                                        .nft_collection_item.external_link
                                    }
                                    target="_blank"
                                  >
                                    <i className="fas fa-link"></i>
                                  </a>
                                </Media>
                              ) : null}

                              {props.singleNftImage.data.nft_collection
                                .instagram_link ? (
                                <Media as="li">
                                  <a
                                    href={
                                      props.singleNftImage.data.nft_collection
                                        .instagram_link
                                    }
                                    target="_blank"
                                  >
                                    <i className="fab fa-instagram"></i>
                                  </a>
                                </Media>
                              ) : null}

                              {props.singleNftImage.data.nft_collection
                                .medium_link ? (
                                <Media as="li">
                                  <a
                                    href={
                                      props.singleNftImage.data.nft_collection
                                        .medium_link
                                    }
                                    target="_blank"
                                  >
                                    <i className="fab fa-medium-m"></i>
                                  </a>
                                </Media>
                              ) : null}

                              {props.singleNftImage.data.nft_collection
                                .discord_link ? (
                                <Media as="li">
                                  <a
                                    href={
                                      props.singleNftImage.data.nft_collection
                                        .discord_link
                                    }
                                    target="_blank"
                                  >
                                    <i className="fab fa-discord"></i>
                                  </a>
                                </Media>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Link
                  to={`/assets/${props.singleNftImage.data.nft_collection.blockchain_type}/${props.singleNftImage.data.nft_collection.nft_collection_unique_id}/${props.singleNftImage.data.nft_collection.contract_address}/${props.singleNftImage.data.nft_collection_item.token_id}`}
                  className="default-btn border-radius-5"
                >
                  View Item
                </Link>
              </Modal.Footer>
            </>
          )
        ) : (
          <>
            <Modal.Header>
              <div className="modal-header-wrapper">
                <h3 className="mb-0 modal-heading-text">
                  Complete your listing
                </h3>
                <div
                  className="custom-close"
                  onClick={() => props.handleCompleteListingClose()}
                >
                  <CloseIcon />
                </div>
              </div>
            </Modal.Header>
            <Modal.Body className="padding-zero">
              <div className="complete-listing-header-sec">
                <div className="complete-listing-left-sec">
                  <Image
                    src={
                      props.singleNftImage.data.nft_collection_item.picture !=
                        ""
                        ? props.singleNftImage.data.nft_collection_item.picture
                        : configuration.get("configData.item_placholder")
                    }
                    alt="Image"
                    className="user-profile-img"
                  />
                  <div className="complete-listing-info">
                    <p>{props.singleNftImage.data.nft_collection.name}</p>
                    <h4>
                      {props.singleNftImage.data.nft_collection_item.name}
                    </h4>
                    {/* <p>Quantity: 1</p> */}
                  </div>
                </div>
                <div className="complete-listing-right-sec">
                  <p className="converted_price right">Price</p>
                  <div className="total-price-card">
                    {props.priceSelect[0] == configuration.get("configData.native_currency_symbol") && (
                      <img src={priceImage} className="price-icon" />
                    )}
                    {/* <i className="fab fa-ethereum"></i> */}
                    <h4>{props.sellData.amount}{" "}{props.priceSelect[0]}</h4>
                  </div>
                  {price.usdConvertedPrice != null &&
                    props.sellData.amount != "" &&
                    Number(props.sellData.amount) > 0 && (
                      <p className="converted_price right mb-0">
                        ≉ <i className="fa fa-usd" aria-hidden="true"></i>{" "}
                        {Number(
                          props.sellData.amount * price.usdConvertedPrice
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}{" "}
                        {price.denomination}
                      </p>
                    )}
                </div>
              </div>
              <div className="complete-listing-body-sec">
                <p>To complete your listing, follow these steps:</p>
                <div className="custom-accordion">
                  {accordionData != null &&
                    accordionData.accordions
                      .filter((accordions) => accordions.isVisible == true)
                      .map((accordion, index) => (
                        // props.singleNftImage.data.nft_collection.collection_type != 2 &&
                          props.singleNftImage.data.nft_collection_item.is_minted == 1 ?
                          <>
                            <CustomAccordion
                              activeId={accordionData.activeAccordion}
                              accordionHeading={accordion.heading}
                              accordionKey={String(index + 1)}
                              accordionContent={accordion.accordionContent}
                              accordionButton={true}
                              OnClick={
                                accordion.name != "approve"
                                  ? () => accordion.onClickFunction()
                                  : accordion.onClickFunction
                              }
                              accordionButtonDisable={
                                accordion.accordionButtonDisable
                              }
                              accordionButtonContent={
                                accordion.accordionButtonContent
                              }
                              spinner
                              spinnerLoading={
                                accordion.spinnerLoading &&
                                props.activeAccordion === String(index + 1)
                              }
                              activeAccordion={accordionData.activeAccordion}
                              loadingComplete={
                                Number(accordionData.activeAccordion) > index + 1
                              }
                            />
                          </> : accordion.name == "offer" ?
                            <>
                              <CustomAccordion
                                activeId={accordionData.activeAccordion}
                                accordionHeading={accordion.heading}
                                accordionKey={String(1)}
                                accordionContent={accordion.accordionContent}
                                accordionButton={true}
                                OnClick={
                                  accordion.name != "approve"
                                    ? () => accordion.onClickFunction()
                                    : accordion.onClickFunction
                                }
                                accordionButtonDisable={
                                  accordion.accordionButtonDisable
                                }
                                accordionButtonContent={
                                  accordion.accordionButtonContent
                                }
                                spinner
                                spinnerLoading={
                                  accordion.spinnerLoading &&
                                  props.activeAccordion === String(index + 1)
                                }
                                activeAccordion={accordionData.activeAccordion}
                                loadingComplete={
                                  Number(accordionData.activeAccordion) > index + 1
                                }
                              />
                            </> : null
                      ))}
                </div>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  sellNftItem: state.nfts.sellNftItem,
  profile: state.users.profile,
  updateSignApprove: state.users.updateSignApprove,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(React.memo(CompleteListingModal));
