import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Tab,
  Row,
  Nav,
  InputGroup,
  FormControl,
  Container,
  Table,
  Button,
} from "react-bootstrap";
// import { authContext } from "../authprovider/AuthProvider";
import { connect } from "react-redux";
import SettingsSidebar from "../SettingsSidebar";
import { Link } from "react-router-dom";
import PaymentIcon from '@material-ui/icons/Payment';
import "./WithDraw.css";
import {
  fetchMoreWithdrawlRequestStart,
  fetchWithdrawlRequestsStart
} from "../../../store/actions/WalletAction";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonCenterLoader from "../../helper/CommonCenterLoader";
import NoDataFound from "../../NoDataFound/NoDataFound";
import configuration from "react-global-configuration";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import WalletWithdrawTableRow from "./WalletWithdrawTableRow";

const WithDrawIndex = (props) => {
  // const { auth } = useContext(authContext);

  useEffect(() => {
    props.dispatch(fetchWithdrawlRequestsStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreWithdraw = () => {
    props.dispatch(fetchMoreWithdrawlRequestStart({
      skip: props.withdrawRequestList.data.withdrawals.length,
      take: 12,
    }));
  }

  return (
    <>
      <div className="profile-setting pages-padding">
        <div className="profile-setting-wrapper">
          <Row className="mt-3 mt-md-0 g-0">
            <SettingsSidebar />
            <Col md={8} lg={9} className="mt-3 mt-md-0">
              <div className="withdraw-wrapper">
                <div className="step-1">
                  <div className="withdraw-header-sec">
                    <div className="withdraw-current-balance">
                      <h4>
                        {!props.withdrawRequestList.loading &&
                          props.withdrawRequestList.data.wallet
                          ? props.withdrawRequestList.data.wallet
                            .remaining_formatted
                          : "0.00 " + configuration.get("configData.currency")}
                      </h4>
                      <p>Current withdraw balance</p>
                    </div>
                    <div className="withdraw-btn-sec">
                      <Link to="/withdraw-money" className="default-btn two border-radius-5 px-4 py-2">
                        <PaymentIcon />
                        <span className="mx-2">Withdraw</span>
                      </Link>
                    </div>
                  </div>
                  <div className="withdraw-table-sec">
                    <div className="section-title">
                      <h3 className="mb-3">Wallet History</h3>
                    </div>
                    {props.withdrawRequestList.loading ?
                      <CommonCenterLoader />
                      : props.withdrawRequestList.data.withdrawals &&
                        props.withdrawRequestList.data.withdrawals.length > 0 ?
                        <div className="withdraw-table-box">
                          <InfiniteScroll
                            dataLength={props.withdrawRequestList.data.withdrawals.length}
                            next={fetchMoreWithdraw}
                            hasMore={props.withdrawRequestList.data.withdrawals.length < props.withdrawRequestList.data.total}
                            loader={<CommonCenterLoader />}
                          >
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Transaction ID</th>
                                  <th>Account</th>
                                  <th>Requested</th>
                                  <th>Paid</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {props.withdrawRequestList.data.withdrawals.map((withdraw, i) =>
                                  <WalletWithdrawTableRow withdraw={withdraw} key={i} />
                                )}
                              </tbody>
                            </Table>
                          </InfiniteScroll>
                        </div>
                        : <NoDataFound />
                    }
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  withdrawRequestList: state.wallet.withdrawRequestList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WithDrawIndex);
