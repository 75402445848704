import React , {useState} from 'react'
// import { ToastContainer } from 'react-toastify';
import EmptyFooter from "./Footer/EmptyFooter"
import EmptyHeader from './Header/EmptyHeader';

const CollectionLayout = (props) => {

  
  return (
    <>
      <EmptyHeader/>
      {/* <ToastContainer /> */}
      {props.children}
    </>
  )
}

export default CollectionLayout
