import React, { createContext, useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { MetaMask, walletconnect, walletConnectProvider } from "./connectors";
import { useEagerConnect, useInactiveListener } from "./web3Hooks";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { UnsupportedChainIdError } from "@web3-react/core";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import { useDispatch, useSelector } from "react-redux";
import { InjectedConnector } from "@web3-react/injected-connector";
import { userLoginStart } from "../../store/actions/UserAction";
import Web3 from "web3";
import configuration from "react-global-configuration";
import { formatEther } from "@ethersproject/units";
import {
  All_supported_ChainIds,
  SUPPORTED_RPC_URLS,
  SupportedChainId,
  CHAIN_INFO,
} from "./chains";
import { changeNetwork } from "./changeNetwork";
import axios from "axios";
const $ = window.$;

export const authContext = createContext({});

const AuthProvider = ({ children }, props) => {
  const context = useWeb3React();
  const dispatch = useDispatch();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;

  const { ethereum } = window;

  const [activatingConnector, setActivatingConnector] = useState();

  const [auth, setAuth] = useState({
    loading: true,
    accounts: "",
    connectWalletStatus: false,
    ethBalance: null,
    logoutStatus: localStorage.getItem("inital_connect"),
    authStatus: false,
    wrongNetwork: false,
    chainId: active ? chainId : Object.values(SupportedChainId)[0],
    sellerContractAddress: "",
  });

  const [price, setPrice] = useState({
    usdConvertedPrice: null,
    totalUsdbalane: null,
    denomination: "USD",
    totalUsdbalaneFormatted: null,
  });

  useEffect(() => {
    getPrice();
  }, [auth.chainId, auth.accounts]);

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 60000);

    if (auth.ethBalance == null) {
      setPrice({
        ...price,
        totalUsdbalane: null,
      });
    }

    return () => clearInterval(interval);
  }, [auth.ethBalance]);

  const getPrice = async () => {
    const priceData = await axios
      .get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${CHAIN_INFO[auth.chainId].apiId}&vs_currencies=usd`,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data[CHAIN_INFO[auth.chainId].apiId].usd;
        } else {
          return null;
        }
      });
    setPrice({
      ...price,
      usdConvertedPrice: priceData,
      totalUsdbalane: getUserequivalentUSDPrice(auth.ethBalance, priceData),
      totalUsdbalaneFormatted: Number(getUserequivalentUSDPrice(
        auth.ethBalance,
        priceData
      )).toLocaleString(undefined, { maximumFractionDigits: 5 }),
    });
  };
  const getUserequivalentUSDPrice = (balance, usd_price) => {
    if (balance && usd_price != null) {
      const eqvPrice = balance * usd_price;

      return Number(String(eqvPrice).substr(0, 6));
    } else {
      return null;
    }
  };

  const loginConnectors = [
    {
      name: "MetaMask",
      logo: "/assets/images/wallet-img/metamask.svg",
      is_popular: true,
      isAvailable: window.ethereum != undefined,
      connectorFunction: MetaMask,
      show: true,
    },
    {
      name: "WalletConnect",
      logo: "/assets/images/wallet-img/wallet-connect.png",
      is_popular: false,
      isAvailable: true,
      connectorFunction: walletconnect,
    },
  ];

  useEffect(() => {
    if (
      activatingConnector &&
      activatingConnector === connector &&
      !auth.loading
    ) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector, auth.loading]);

  const handleConnector = async (connector) => {
    const network =
      ethereum && ethereum.networkVersion ? ethereum.networkVersion : "";

    setAuth({
      ...auth,
      loading: true,
      connectWalletStatus: true,
    });

    setActivatingConnector(connector);

    // if (connector instanceof WalletConnectConnector) {
    //   try {
    //     if (auth.chainId === 1) {
    //       connector.constructor({
    //         rpc: {
    //           1: "https://mainnet.infura.io/v3/3196e6e3e2ba40d4af441449bf3a2c30",
    //         },
    //         chainId: 1,
    //         supportedChainIds: All_supported_ChainIds,
    //       });
    //     } else {
    //       connector.constructor({
    //         rpc: SUPPORTED_RPC_URLS[auth.chainId],
    //         chainId: auth.chainId,
    //         //qrcode: true,
    //         supportedChainIds: All_supported_ChainIds,
    //       });
    //     }
    //   } catch (error) {}
    // }

    if (connector instanceof WalletConnectConnector) {
      try {
        walletConnectProvider.rpcUrl = SUPPORTED_RPC_URLS[auth.chainId];
        walletConnectProvider.chainId = auth.chainId;
        await walletConnectProvider
          .enable()
          .then((account) => saveAccountDetails(account[0]))
          .catch((error) => {
            console.log(error)
            setAuth({
              ...auth,
              loading: false,
              connectWalletStatus: false,
            });
            setActivatingConnector(undefined)
          });
      } catch (error) {
        console.log(error);
        setAuth({
          ...auth,
          loading: false,
          connectWalletStatus: false,
        });
        setActivatingConnector(undefined)
      }
    }

    if (connector instanceof InjectedConnector) {
      connector.walletConnectProvider = undefined;
      if (
        All_supported_ChainIds.includes(Number(auth.chainId)) &&
        Number(network) === Number(auth.chainId)
      ) {
        setAuth({
          ...auth,
          wrongNetwork: false,
        });
        activate(connector);
      } else {
        console.log("change network");
        changeNetwork(Number(auth.chainId))
          .then((response) => {
            if (response == undefined) {
              setAuth({
                ...auth,
                loading: false,
                wrongNetwork: true,
                connectWalletStatus: false,
              });
              setActivatingConnector(undefined)
            }
            console.log("change networl res", response)
           })
          .catch((error) => {
            console.error("Failed to switch networks", error);
            const notificationMessage = getErrorNotificationMessage(
              "Failed to switch networks"
            );
            dispatch(createNotification(notificationMessage));
            setAuth({
              ...auth,
              loading: false,
              wrongNetwork: true,
              connectWalletStatus: false,
            });
            setActivatingConnector(undefined)
          });
      }
    } else {
      activate(connector);
    }
  };

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect(auth.logoutStatus);

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !activatingConnector);

  function getErrorMessage(error) {
    setAuth({
      ...auth,
      loading: false,
      connectWalletStatus: false,
    });

    setActivatingConnector(undefined);

    if (error instanceof NoEthereumProviderError) {
      const notificationMessage = getErrorNotificationMessage(
        "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile."
      );
      dispatch(createNotification(notificationMessage));
    } else if (error instanceof UnsupportedChainIdError) {
      // const notificationMessage = getErrorNotificationMessage(
      //   "You're connected to an unsupported network."
      // );
      // dispatch(createNotification(notificationMessage));
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorWalletConnect ||
      error instanceof UserRejectedRequestErrorFrame
    ) {
      const notificationMessage = getErrorNotificationMessage(
        "User rejected the request"
      );
      dispatch(createNotification(notificationMessage));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        "An unknown error occurred. Check the console for more details"
      );
      dispatch(createNotification(notificationMessage));
    }
  }

  useEffect(() => {
    if (error) {
      getErrorMessage(error);
    }
  }, [error]);

  useEffect(() => {
    if (
      chainId != undefined &&
      !All_supported_ChainIds.includes(Number(chainId)) &&
      connector instanceof InjectedConnector
    ) {
      deactivate();
    } else if (connector instanceof InjectedConnector) {
      activate(MetaMask);
    }
  }, [chainId]);

  const hanldeLogout = () => {
    setAuth({
      ...auth,
      loading: false,
      accounts: "",
      authStatus: false,
      wrongNetwork: false,
      connectWalletStatus: false,
      ethBalance: null,
      logoutStatus: "false",
      sellerContractAddress: "",
    });
    localStorage.setItem("inital_connect", false);
    deactivate();
  };

  const getBalance = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }

    const web3 = window.web3;

    await library
      .getBalance(account)
      .then((balance) => {
        if (balance) {
          console.log(balance, account, "balance");
          let sellerAddress = "";
          if (auth.chainId == SupportedChainId.ETHEREUM) {
            sellerAddress = configuration.get("configData.seller_contract_address_ethereum")
              ? configuration.get("configData.seller_contract_address_ethereum")
              : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
          } else if (auth.chainId == SupportedChainId.BINANCE) {
            sellerAddress = configuration.get("configData.seller_contract_address_binance")
              ? configuration.get("configData.seller_contract_address_binance")
              : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
          } else if (auth.chainId == SupportedChainId.POLYGON) {
            sellerAddress = configuration.get("configData.seller_contract_address_polygon")
              ? configuration.get("configData.seller_contract_address_polygon")
              : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
          }
          setAuth({
            ...auth,
            accounts: account,
            authStatus: true,
            connectWalletStatus: false,
            ethBalance: formatEther(balance),
            logoutStatus: localStorage.getItem("inital_connect"),
            wrongNetwork: false,
            connectWalletStatus: false,
            sellerContractAddress: sellerAddress,
          });
        }
      })
      .catch((e) => {
        //console.log(e);
        setAuth({
          ...auth,
          ethBalance: null,
          connectWalletStatus: false,
          wrongNetwork: false,
        });
      });
  };

  const saveAccountDetails = async (wallet_address) => {
    console.log(wallet_address);

    const account = wallet_address;

    // window.web3 = new Web3(await connector.getProvider());

    if (connector instanceof WalletConnectConnector) {
      // await walletConnectProvider.enable();
      window.web3 = new Web3(walletConnectProvider);
      //window.web3 = new Web3(await library.provider)
    }

    if (connector instanceof InjectedConnector) {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      }
    }

    try {
      const web3 = window.web3;
      if (account.length > 0) {
        localStorage.setItem("inital_connect", true);
        $("#connect-wallet-popup").modal("hide");
        await library
          .getBalance(account)
          .then((balance) => {
            if (balance) {
              let sellerAddress = "";
              if (auth.chainId == SupportedChainId.ETHEREUM) {
                sellerAddress = configuration.get("configData.seller_contract_address_ethereum")
                  ? configuration.get("configData.seller_contract_address_ethereum")
                  : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
              } else if (auth.chainId == SupportedChainId.BINANCE) {
                sellerAddress = configuration.get("configData.seller_contract_address_binance")
                  ? configuration.get("configData.seller_contract_address_binance")
                  : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
              } else if (auth.chainId == SupportedChainId.POLYGON) {
                sellerAddress = configuration.get("configData.seller_contract_address_polygon")
                  ? configuration.get("configData.seller_contract_address_polygon")
                  : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
              }
              setAuth({
                ...auth,
                accounts: account,
                wrongNetwork: false,
                authStatus: true,
                connectWalletStatus: false,
                ethBalance: formatEther(balance),
                logoutStatus: localStorage.getItem("inital_connect"),
                loading: false,
                chainId: chainId,
                sellerContractAddress: sellerAddress,
              });
            }
          })
          .catch((e) => {
            console.log(e);
            setAuth({
              ...auth,
              wrongNetwork: false,
              ethBalance: null,
              loading: false,
            });
          });
      } else {
        // hanldeLogout();
        const notificationMessage = getSuccessNotificationMessage(
          "Signed out successfully"
        );
        dispatch(createNotification(notificationMessage));
      }
    } catch (error) {
      setAuth({ ...auth, connectWalletStatus: false, authStatus: false });
    }
  };

  const getProviderSigner = async (message_content) => {
    let txhash;

    await library
      .getSigner(account)
      .signMessage(message_content)
      .then((signature) => (txhash = signature))
      .catch((error) => {
        const notificationMessage = getErrorNotificationMessage(error);
        dispatch(createNotification(notificationMessage));
      });
    return txhash;
  };

  useEffect(() => {
    if (account) {
      saveAccountDetails(account);
    }
  }, [account]);

  useEffect(() => {
    hanldeLogout();
  }, [auth.chainId])

  return (
    <authContext.Provider
      value={{
        auth,
        context,
        handleConnector,
        loginConnectors,
        activatingConnector,
        hanldeLogout,
        getProviderSigner,
        getBalance,
        setAuth,
        price,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export default AuthProvider;
