import {
  ADD_USER_CARD_FAILURE,
  ADD_USER_CARD_START,
  ADD_USER_CARD_SUCCESS,
  FETCH_USER_CARD_LIST_FAILURE,
  FETCH_USER_CARD_LIST_START,
  FETCH_USER_CARD_LIST_SUCCESS,
  SET_DEFAULT_USER_CARD_FAILURE,
  SET_DEFAULT_USER_CARD_START,
  SET_DEFAULT_USER_CARD_SUCCESS,
  DELETE_USER_CARD_FAILURE,
  DELETE_USER_CARD_START,
  DELETE_USER_CARD_SUCCESS,
  ADD_USER_BILLING_ACCOUNTS_FAILURE,
  ADD_USER_BILLING_ACCOUNTS_START,
  ADD_USER_BILLING_ACCOUNTS_SUCCESS,
  FETCH_USER_BILLING_ACCOUNTS_FAILURE,
  FETCH_USER_BILLING_ACCOUNTS_START,
  FETCH_USER_BILLING_ACCOUNTS_SUCCESS,
  SET_DEFAULT_USER_BILLING_ACCOUNTS_FAILURE,
  SET_DEFAULT_USER_BILLING_ACCOUNTS_START,
  SET_DEFAULT_USER_BILLING_ACCOUNTS_SUCCESS,
  DELETE_USER_BILLING_ACCOUNTS_FAILURE,
  DELETE_USER_BILLING_ACCOUNTS_START,
  DELETE_USER_BILLING_ACCOUNTS_SUCCESS,
  FETCH_WALLET_DETAILS_FAILURE,
  FETCH_WALLET_DETAILS_START,
  FETCH_MORE_WALLET_DETAILS_START,
  FETCH_WALLET_DETAILS_SUCCESS,
  WALLET_PAYMENT_BY_STRIPE_FAILURE,
  WALLET_PAYMENT_BY_STRIPE_START,
  WALLET_PAYMENT_BY_STRIPE_SUCCESS,
  WITHDRAWAL_REQUEST_START,
  WITHDRAWAL_REQUEST_SUCCESS,
  WITHDRAWAL_REQUEST_FAILURE,
  CANCEL_WITHDRAWAL_REQUEST_START,
  CANCEL_WITHDRAWAL_REQUEST_SUCCESS,
  CANCEL_WITHDRAWAL_REQUEST_FAILURE,
  FETCH_WITHDRAWL_REQUESTS_START,
  FETCH_WITHDRAWL_REQUESTS_SUCCESS,
  FETCH_WITHDRAWL_REQUESTS_FAILURE,
  FETCH_MORE_WITHDRAWAL_REQUESTS_START,
  VIEW_WITHDRAWAL_REQUEST_SUCCESS,
  VIEW_WITHDRAWAL_REQUEST_FAILURE,
  VIEW_WITHDRAWAL_REQUEST_START,
  RESET_WITHDRAWL_REQUEST_SUCCESS,
} from "../actions/ActionConstant";

const initialState = {
  addUserCard: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  userCardList: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null
  },
  defaultUserCard: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  deleteUserCard: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  addUserBillingAccounts: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  userBillingAccounts: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null
  },
  defaultUserBillingAccounts: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  deleteUserBillingAccounts: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  walletDetails: {
    data: {
      wallet_payments: [],
      wallet: {},
      total: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null
  },
  walletPaymentByStripe: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  withdrawRequest: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  cancelWithdrawRequest: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  withdrawRequestList: {
    data: {
      withdrawals: [],
      wallet: {},
      total: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  viewWithdrawRequest: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_CARD_START:
      return {
        ...state,
        addUserCard: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        },
      };
    case ADD_USER_CARD_SUCCESS:
      return {
        ...state,
        addUserCard: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case ADD_USER_CARD_FAILURE:
      return {
        ...state,
        addUserCard: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_USER_CARD_LIST_START:
      return {
        ...state,
        userCardList: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading"
        }
      };
    case FETCH_USER_CARD_LIST_SUCCESS:
      return {
        ...state,
        userCardList: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null
        }
      };
    case FETCH_USER_CARD_LIST_FAILURE:
      return {
        ...state,
        userCardList: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null
        }
      };
    case SET_DEFAULT_USER_CARD_START:
      return {
        ...state,
        defaultUserCard: {
          data: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case SET_DEFAULT_USER_CARD_SUCCESS:
      return {
        ...state,
        defaultUserCard: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case SET_DEFAULT_USER_CARD_FAILURE:
      return {
        ...state,
        defaultUserCard: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case DELETE_USER_CARD_START:
      return {
        ...state,
        deleteUserCard: {
          data: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case DELETE_USER_CARD_SUCCESS:
      return {
        ...state,
        deleteUserCard: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case DELETE_USER_CARD_FAILURE:
      return {
        ...state,
        deleteUserCard: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case ADD_USER_BILLING_ACCOUNTS_START:
      return {
        ...state,
        addUserBillingAccounts: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        },
      };
    case ADD_USER_BILLING_ACCOUNTS_SUCCESS:
      return {
        ...state,
        addUserBillingAccounts: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case ADD_USER_BILLING_ACCOUNTS_FAILURE:
      return {
        ...state,
        addUserBillingAccounts: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_USER_BILLING_ACCOUNTS_START:
      return {
        ...state,
        userBillingAccounts: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading"
        }
      };
    case FETCH_USER_BILLING_ACCOUNTS_SUCCESS:
      return {
        ...state,
        userBillingAccounts: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null
        }
      };
    case FETCH_USER_BILLING_ACCOUNTS_FAILURE:
      return {
        ...state,
        userBillingAccounts: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null
        }
      };
    case SET_DEFAULT_USER_BILLING_ACCOUNTS_START:
      return {
        ...state,
        defaultUserBillingAccounts: {
          data: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case SET_DEFAULT_USER_BILLING_ACCOUNTS_SUCCESS:
      return {
        ...state,
        defaultUserBillingAccounts: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case SET_DEFAULT_USER_BILLING_ACCOUNTS_FAILURE:
      return {
        ...state,
        defaultUserBillingAccounts: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case DELETE_USER_BILLING_ACCOUNTS_START:
      return {
        ...state,
        deleteUserBillingAccounts: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case DELETE_USER_BILLING_ACCOUNTS_SUCCESS:
      return {
        ...state,
        deleteUserBillingAccounts: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case DELETE_USER_BILLING_ACCOUNTS_FAILURE:
      return {
        ...state,
        deleteUserBillingAccounts: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_WALLET_DETAILS_START:
      return {
        ...state,
        walletDetails: {
          data: {
            wallet_payments: [],
            wallet: {},
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading"
        }
      };
    case FETCH_MORE_WALLET_DETAILS_START:
      return state;
    case FETCH_WALLET_DETAILS_SUCCESS:
      return {
        ...state,
        walletDetails: {
          data: {
            wallet_payments: [...state.walletDetails.data.wallet_payments, ...action.data.wallet_transactions],
            wallet: action.data.user_wallet,
            total: action.data.total_wallet_transactions,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null
        }
      };
    case FETCH_WALLET_DETAILS_FAILURE:
      return {
        ...state,
        walletDetails: {
          data: state.walletDetails.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null
        }
      };
    case WALLET_PAYMENT_BY_STRIPE_START:
      return {
        ...state,
        walletPaymentByStripe: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        },
      };
    case WALLET_PAYMENT_BY_STRIPE_SUCCESS:
      return {
        ...state,
        walletPaymentByStripe: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case WALLET_PAYMENT_BY_STRIPE_FAILURE:
      return {
        ...state,
        walletPaymentByStripe: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case WITHDRAWAL_REQUEST_START:
      return {
        ...state,
        withdrawRequest: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        withdrawRequest: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null
        }
      };
    case WITHDRAWAL_REQUEST_FAILURE:
      return {
        ...state,
        withdrawRequest: {
          data: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case CANCEL_WITHDRAWAL_REQUEST_START:
      return {
        ...state,
        cancelWithdrawRequest: {
          data: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case CANCEL_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        cancelWithdrawRequest: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case CANCEL_WITHDRAWAL_REQUEST_FAILURE:
      return {
        ...state,
        cancelWithdrawRequest: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_WITHDRAWL_REQUESTS_START:
      return {
        ...state,
        withdrawRequestList: {
          data: {
            withdrawals: [],
            wallet: {},
            total: 0
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case FETCH_MORE_WITHDRAWAL_REQUESTS_START:
      return state;
    case FETCH_WITHDRAWL_REQUESTS_SUCCESS:
      return {
        ...state,
        withdrawRequestList: {
          data: {
            withdrawals: [...state.withdrawRequestList.data.withdrawals, ...action.data.user_withdrawals],
            wallet: action.data.user_wallet,
            total: action.data.total_withdrawals,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case RESET_WITHDRAWL_REQUEST_SUCCESS:
      return {
        ...state,
        withdrawRequestList: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case FETCH_WITHDRAWL_REQUESTS_FAILURE:
      return {
        ...state,
        withdrawRequestList: {
          data: state.withdrawRequestList.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case VIEW_WITHDRAWAL_REQUEST_START:
      return {
        ...state,
        viewWithdrawRequest: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case VIEW_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        viewWithdrawRequest: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case VIEW_WITHDRAWAL_REQUEST_FAILURE:
      return {
        ...state,
        viewWithdrawRequest: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    default:
      return state;
  }
}

export default walletReducer;