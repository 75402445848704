import { Tab } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Container } from "react-bootstrap";
import CustomTabs from "../../customComponents/CustomTabs";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { fetchNftCollectionsListForOthersStart } from "../../store/actions/NftAction";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { authContext } from "../authprovider/AuthProvider";
import ExploreCardLoader from "../loaders/ExploreCardLoader";
import NoDataFound from "../NoDataFound/NoDataFound";
const queryString = require("query-string");

const customTabLabel = (name) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 0.5,
          pr: 0,
        }}
      >
        <Typography variant="body2">{name}</Typography>
      </Box>
    </>
  );
};

const Explore = (props) => {
  const { categories } = props;

  const { auth } = useContext(authContext);

  const history = useHistory();

  const parsedTab = queryString.parse(props.location.search);

  const [initialState, setInitialState] = useState(false);

  const [activeTab, setActiveTab] = useState(
    Object.keys(parsedTab).length > 0 ? parsedTab.tab : 0
  );

  useEffect(() => {
    const query = queryString.stringify(
      { tab: activeTab },
      { arrayFormat: "bracket-separator", arrayFormatSeparator: "|" }
    );
    history.push({
      pathname: props.location.pathname,
      search: query,
    });
    setActiveTab(activeTab);
    if (!initialState && activeTab) {
      props.dispatch(
        fetchNftCollectionsListForOthersStart({ category_id: activeTab })
      );
      setInitialState(true);
    }
  }, [activeTab]);

  useEffect(() => {
    if (initialState && activeTab) {
      setActiveTab(
        Object.keys(parsedTab).length > 0 ? Number(parsedTab.tab) : 0
      );
      props.dispatch(
        fetchNftCollectionsListForOthersStart({
          category_id:
            Object.keys(parsedTab).length > 0 ? Number(parsedTab.tab) : 0,
        })
      );
    }
  }, [props.location.search]);

  useEffect(() => {
    if (!categories.loading) {
      setActiveTab(
        Object.keys(parsedTab).length > 0
          ? Number(parsedTab.tab)
          : categories.data.categories && categories.data.categories.length > 0
          ? categories.data.categories[0].category_id
          : categories.data.categories
      );
    }
  }, [categories.data]);

  const handleTabChange = (event, category_id) => {
    setActiveTab(category_id);
    props.dispatch(
      fetchNftCollectionsListForOthersStart({ category_id: category_id })
    );
  };

  return (
    <>
      <div className="profile-banner-section ">
        <div className="profile-cover-image explore-banner-image">
          <img
            src={window.location.origin + "/assets/images/banners/explore-cover.png"}
            alt=""
          />
        </div>
      </div>
      <div className="">
        <h2 className="explore-title text-center">Explore Collections</h2>

        <div className="explore-tabs">
          {!categories.loading &&
            categories.data.categories &&
            categories.data.categories.length > 0 && (
              <CustomTabs
                activeTab={activeTab}
                handleTabChange={handleTabChange}
              >
                {categories.data.categories.filter(cat => cat.status === 1).map((category, index) => (
                  <Tab
                    aria-label="phone"
                    label={customTabLabel(category.name)}
                    value={category.category_id}
                  />
                ))}
              </CustomTabs>
            )}
        </div>
        {props.collectionListForOthers.loading ? (
          <>
            <div className="explore-contents">
              <Row className="g-0 px-2 px-md-5">
                <ExploreCardLoader count={6} explore />
              </Row>
            </div>
          </>
        ) : (
          <div className="explore-contents">
            <Row className="g-0 px-5">
              {props.collectionListForOthers.data.nft_collections.length > 0
                ? props.collectionListForOthers.data.nft_collections.map(
                    (nft_collection, index) => (
                      <Col md={6} lg={6} xl={4} key={index}>
                        <Link
                          to={`/collection/${nft_collection.nft_collection_unique_id}`}
                        >
                          <div className="profile-banner-section explore-card">
                            <div className="profile-cover-image ">
                              <img src={nft_collection.cover} alt="" />
                            </div>
                            <div className="user-details">
                              <div className="profile-image explore-img">
                                <img src={nft_collection.picture} alt="" />
                              </div>
                              <div className="explore-detail text-center">
                              <div className="verified-icon-sec justify-center">
                                <h4 className="m-0">{nft_collection.name}</h4>
                                {nft_collection.is_verified == 1 && (
                                  <img 
                                    className="verified-icon" 
                                    src={window.location.origin + "/assets/images/icons/verified.png"} 
                                    alt="Verified Icon" />
                                )}
                              </div>
                                <p>
                                  by{" "}
                                  <Link
                                    to={!props.profile.loading && Object.keys(props.profile.data).length > 0
                                      && nft_collection.user.user_unique_id == props.profile.data.user.user_unique_id
                                        ? `/profile`
                                        : `/account/${nft_collection.user.user_unique_id}`
                                    }
                                  >
                                    {
                                      nft_collection.deployed_wallet_address_formatted
                                    }
                                  </Link>
                                </p>
                                <p>{nft_collection.description}</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    )
                  )
                : <NoDataFound />}
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  categories: state.nfts.categoriesList,
  collectionListForOthers: state.nfts.collectionListForOthers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Explore);
