import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import ImageIcon from "@material-ui/icons/Image";
import CustomSelect from "../../customComponents/CustomSelect";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { InputGroup, FormControl } from "react-bootstrap";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  nftCollectionsSaveStart,
  nftCollectionNameValidationStart,
} from "../../store/actions/NftAction";
import { useHistory } from "react-router-dom";
import { authContext } from "../authprovider/AuthProvider";
import NftToken from "../../abis/NftToken.json";
import Web3 from "web3";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import { Switch } from "@material-ui/core";
import { CHAIN_INFO, SupportedChainId } from "../authprovider/chains";
import configuration from "react-global-configuration";

const CreateCollection = (props) => {
  const { categories } = props;

  const history = useHistory();

  const { auth, connectWallet, getProviderSigner } = useContext(authContext);

  const [networkSelect, setNetworkSelect] = useState([
    { name: "Polygon", id: 1 },
  ]);

  const [skipInitalRender, setSkipInitalRender] = useState(false);

  const [validCollectionName, setValidCollectionName] = useState();

  const [termsAgreed, setTermsAgreed] = useState(false)

  const [collectionData, setCollectionData] = useState({
    name: "",
    symbol: "",
    external_link: "",
    category_id: "",
    website_link: "",
    discord_link: "",
    twitter_link: "",
    instagram_link: "",
    medium_link: "",
    telegram_link: "",
    description: "",
    contract_address: "",
    royalty_percentage: "",
    collection_type: "1",
    royalty_wallet_address: "",
    deployed_wallet_address: "",
    royalty_type: ""
  });

  const [inputData, setInputData] = useState({
    name: "",
    symbol: "",
  });

  const [buttonContent, setButtonContent] = useState("");

  const [logoImage, setLogoImage] = useState({
    file: "",
    preview_image: "",
  });

  const [coverImage, setCoverImage] = useState({
    file: "",
    preview_image: "",
  });

  const [royaltyType, setRoyaltyType] = useState(1);

  const createNftCollectionchema = Yup.object().shape({
    picture: Yup.mixed().required("Logo is required"),
    name: Yup.string().required("Collection name is required"),
    symbol: Yup.string().required("Symbol is required"),
    royalty_wallet_address: Yup.string().when("royalty_percentage", {
      is: (royalty_percentage) => Number(royalty_percentage) > 0,
      then: Yup.string().required("Royalty Wallet address is required"),
      otherwise: Yup.string(),
    }),
  });

  const handleNetworkSelect = (value) => {
    setNetworkSelect([value]);
  };

  const handleAddImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setLogoImage({
        ...logoImage,
        file: file,
        preview_image: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleAddCoverImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setCoverImage({
        ...coverImage,
        file: file,
        preview_image: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (values) => {
    const newData = {
      ...values,
      cover: coverImage.file,
      royalty_percentage:
        collectionData.royalty_percentage != ""
          ? collectionData.royalty_percentage
          : "0",
    };

    if (collectionData.collection_type == "1") {
      deployContract(newData);
    } else {
      deployLazyMintingContract(newData);
    }
  };

  const handleSocialLinkChanges = (event) => {
    setCollectionData({
      ...collectionData,
      [event.target.name]: event.target.value,
    });
  };

  const handleRoyaltyChange = (event) => {
    setCollectionData({
      ...collectionData,
      royalty_percentage: isInt(Number(event.target.value))
        ? event.target.value
        : Number(event.target.value).toFixed(1),
    });
  };

  const handleCategorySelect = (value) => {
    setCollectionData({
      ...collectionData,
      category_id: value,
    });
  };

  useEffect(() => {
    if (
      !props.collectionSave.loading &&
      Object.keys(props.collectionSave.data).length > 0
    ) {
      history.push(
        `/assets/collection/${props.collectionSave.data.nft_collection_unique_id}`
      );
    }
  }, [props.collectionSave.data]);

  useEffect(() => {
    if (skipInitalRender) {
      if (collectionData.name.length == 0) {
        setValidCollectionName(null);
      }
      const delayDebounceFn = setTimeout(() => {
        if (collectionData.name.length > 2) {
          props.dispatch(
            nftCollectionNameValidationStart({ name: collectionData.name })
          );
        }
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    setSkipInitalRender(true);
  }, [collectionData.name]);

  useEffect(() => {
    if (skipInitalRender && !props.collectionNameValidation.loading) {
      if (props.collectionNameValidation.data.code == 122) {
        setValidCollectionName(true);
      } else {
        setValidCollectionName(false);
      }
    }
  }, [props.collectionNameValidation.data]);

  // blockchain part
  const deployContract = async (newData) => {
    if (!auth.authStatus) {
      const notificationMessage = getErrorNotificationMessage("Please connect metamask.");
      props.dispatch(createNotification(notificationMessage));
      return false;
    }
    setButtonContent("Deploying contract...");

    let transaction_hash = "";

    const web3 = window.web3;
    const ecr721Token = new web3.eth.Contract(NftToken.abi);
    try {
      const res = await ecr721Token
        .deploy({
          data: NftToken.bytecode,
          arguments: [
            collectionData.name,
            collectionData.symbol,
            auth.accounts,
            collectionData.royalty_wallet_address == ""
              ? auth.accounts
              : collectionData.royalty_wallet_address,
            collectionData.royalty_percentage == ""
              ? "0"
              : String(Number(collectionData.royalty_percentage) * 10),
            royaltyType == 1 ? true : false,
          ],
        })
        .send(
          {
            from: auth.accounts,
          },
          function (error, transactionHash) {
            // API call....
            console.log("Txt", transactionHash);
            transaction_hash = transactionHash;
          }
        )
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("con", confirmationNumber);
        })
        .then(async (newContractInstance) => {
          console.log("New token created.", newContractInstance.address);
          console.log(
            "name",
            await newContractInstance.methods.name.call().toString()
          );
          // Todo - Send notification.
          console.log("Contract Address", newContractInstance.options.address); // instance with the new contract address
          // saving contract address

          const updatedData = {
            ...newData,
            contract_address: newContractInstance.options.address,
            deployed_wallet_address: auth.accounts,
            blockchain_type: CHAIN_INFO[auth.chainId].nativeCurrency.symbol,
            transaction_hash: transaction_hash,
            royalty_type: royaltyType
          };
          props.dispatch(nftCollectionsSaveStart(updatedData));
          // props.dispatch(
          //   settingsUpdateStart({
          //     contract_address: newContractInstance.options.address,
          //   })
          // );
          setButtonContent("");
          // Save the token contract address.
        });
    } catch (error) {
      console.log("errr", error);
      const notificationMessage = getErrorNotificationMessage(error.message);
      props.dispatch(createNotification(notificationMessage));
      setButtonContent("");
    }
  };

  //prevent reload

  useEffect(() => {
    if (buttonContent != "") {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [buttonContent]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    if (collectionData.royalty_percentage == "") {
      setCollectionData({
        ...collectionData,
        royalty_percentage: "",
        royalty_wallet_address: "",
      });
    }
  }, [collectionData.royalty_percentage]);

  function isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  const handleTermsChange = () => {
    setTermsAgreed(prev => !prev)
  }

  // blockchain part
  const deployLazyMintingContract = async (newData) => {
    if (!auth.authStatus) {
      const notificationMessage = getErrorNotificationMessage("Please connect metamask.");
      props.dispatch(createNotification(notificationMessage));
      return false;
    }
    setButtonContent("Deploying contract...");

    const txMessage = `
      Welcome to ${configuration.get("configData.site_name")}!
  
      Click to sign in and accept the ${configuration.get(
      "configData.site_name"
    )} Terms of Service: ${configuration.get("configData.frontend_url")}
  
        This request will not trigger a blockchain transaction or cost any gas fees.
  
        Wallet address:
        ${auth.accounts}`;

    let result = await getProviderSigner(txMessage);
    if (result) {
      let lazy_contract_address = (auth.chainId == configuration.get("configData.binance_network_id") ?
        configuration.get("configData.binance_lazy_minting_contract_address") : auth.chainId == configuration.get("configData.ethereum_network_id") ?
          configuration.get("configData.ethereum_lazy_minting_contract_address") : configuration.get("polygon_lazy_minting_contract_address.ethereum_network_id"));

      const updatedData = {
        ...newData,
        contract_address: lazy_contract_address,
        deployed_wallet_address: auth.accounts,
        blockchain_type: CHAIN_INFO[auth.chainId].nativeCurrency.symbol,
        sign_transaction_hash: result,
        royalty_type: royaltyType
      };
      props.dispatch(nftCollectionsSaveStart(updatedData));
    } else {
      const notificationMessage = getErrorNotificationMessage("Sign transaction failed!");
      props.dispatch(createNotification(notificationMessage));
      setButtonContent("");
    }
  };

  return (
    <>
      <div className="collection-widget-area pages-padding">
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-9 mx-auto">
              <div className="collection-form-area">
                <div className="collection-form no-shadow">
                  <div className="section-title">
                    <h2>Create your collection</h2>
                  </div>
                  <Formik
                    initialValues={{
                      ...collectionData,
                      picture: logoImage.file,
                      cover: coverImage.file,
                    }}
                    className="w-100"
                    validationSchema={createNftCollectionchema}
                    onSubmit={(values) => handleSubmit(values)}
                    enableReinitialize
                  >
                    {({ errors, touched, setFieldValue, values }) => (
                      <>
                        <Form>
                          <div className="profile-outer">
                            <h3>
                              Logo image <span className="text-danger">*</span>
                            </h3>
                            <p>
                              This image will also be used for navigation. 350 x
                              350 recommended
                            </p>

                            <div className="profileButton custom-input">
                              {logoImage.preview_image != "" && (
                                <div className="preview-image">
                                  <img src={logoImage.preview_image} alt="" />
                                </div>
                              )}
                              <input
                                className="profileButton-input "
                                type="file"
                                name="logo"
                                accept="image/*"
                                id="upload"
                                onChange={(event) =>
                                  handleAddImageChange(event)
                                }
                              />
                              <label
                                className="profileButton-button ripple-effect"
                                htmlFor="upload"
                              >
                                <ImageIcon />
                              </label>
                            </div>
                            <ErrorMessage
                              component="div"
                              name="picture"
                              className="invalid-feedback mt-3"
                            />
                          </div>
                          <div className="preview-box">
                            <h3>Banner image </h3>
                            <p>
                              This image will appear at the top of your
                              collection page. Aviod including too much text in
                              this banner image, as the dimensions change on the
                              devices. 1400 x 400 recommended
                            </p>

                            <div className="previewButton custom-input-banner">
                              {coverImage.preview_image != "" && (
                                <div className="preview-image">
                                  <img src={coverImage.preview_image} alt="" />
                                </div>
                              )}
                              <input
                                className="previewButton-input"
                                type="file"
                                name="cover"
                                accept="image/*"
                                id="uploadCover"
                                onChange={(event) =>
                                  handleAddCoverImageChange(event)
                                }
                              />
                              <label
                                className="previewButton-button ripple-effect"
                                htmlFor="uploadCover"
                              >
                                <ImageIcon />
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group ">
                              <h5>
                                Name <span className="text-danger">*</span>
                              </h5>
                              <div className="controls">
                                <Field
                                  type="text"
                                  name="name"
                                  placeholder="Collection name"
                                  className={`form-control ${touched.name && errors.name
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  value={collectionData.name}
                                  onChange={(event) =>
                                    handleSocialLinkChanges(event)
                                  }
                                />
                                <ErrorMessage
                                  component="div"
                                  name="name"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                              {validCollectionName != undefined &&
                                validCollectionName && (
                                  <p className="text-success mt-3">
                                    Valid collection name
                                  </p>
                                )}
                              {validCollectionName != undefined &&
                                !validCollectionName && (
                                  <p className="invalid-feedback mt-3">
                                    inValid collection name
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group ">
                              <h5>
                                Symbol <span className="text-danger">*</span>
                              </h5>
                              <div className="controls">
                                <Field
                                  type="text"
                                  name="symbol"
                                  placeholder="Collection symbol"
                                  className={`form-control ${touched.symbol && errors.symbol
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  value={collectionData.symbol}
                                  onChange={(event) =>
                                    handleSocialLinkChanges(event)
                                  }
                                />
                                <ErrorMessage
                                  component="div"
                                  name="symbol"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group ">
                              <h5>Description</h5>
                              <div className="controls">
                                <textarea
                                  type="text"
                                  name="description"
                                  placeholder="Description"
                                  className={`form-control`}
                                  value={collectionData.description}
                                  onChange={(event) =>
                                    handleSocialLinkChanges(event)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <label>External Link </label>
                              <p>
                                Marketplace will include a link to this URL on
                                this item's detial page, so that users can click
                                to learn more about it. You are welcome to link
                                to your own webpage with more details.
                              </p>
                              <input
                                type="text"
                                name="external_link"
                                className="form-control"
                                placeholder="http://yoursite.io/item/123"
                                value={collectionData.external_link}
                                onChange={(event) =>
                                  handleSocialLinkChanges(event)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="collection-category">
                              <h3>Category</h3>
                              <p>
                                Adding a category will help make your item
                                discoverable on Marketplace.
                              </p>
                              {categories.loading ? null : categories.data
                                .categories.length > 0 ? (
                                <ul>
                                  {categories.data.categories
                                    .filter((cat) => cat.status === 1)
                                    .map((category) => (
                                      <li
                                        className={
                                          category.category_id ===
                                            collectionData.category_id
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        <p
                                          onClick={() =>
                                            handleCategorySelect(
                                              category.category_id
                                            )
                                          }
                                        >
                                          {category.name}
                                        </p>
                                      </li>
                                    ))}
                                </ul>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 mb-4">
                            <div className="collection-details-list">
                              <div className="collection-details-list-item">
                                <div className="collection-details-list-content">
                                  <i
                                    className="Iconreact__Icon-sc-1gugx8q-0 irnoQt EventHistory--icon material-icons EventHistory--icon"
                                    value="child_friendly"
                                    size="24"
                                  >
                                    child_friendly
                                  </i>
                                  <div className="collection-details-list-main">
                                    <span className="collection-details-list-label">
                                      Lazy Minting
                                    </span>
                                    <p className="collection-details-list-header">
                                      Buyer will pay gas fees for minting.
                                    </p>
                                  </div>
                                </div>
                                <div className="collection-details-list-side">
                                  <Switch
                                    name="collection_type"
                                    value={
                                      collectionData.collection_type == "2"
                                        ? "1"
                                        : "2"
                                    }
                                    checked={
                                      collectionData.collection_type == "2"
                                    }
                                    onChange={(event) =>
                                      handleSocialLinkChanges(event)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <label>Royalties</label>
                              <p>
                                Collect a fee when a user re-sells an item you
                                originally created. This is deducted from the
                                final sale price and paid monthly to a payout
                                address of your choosing.
                              </p>
                              <div className="custom-radio d-flex mt-3 mb-3">
                                <div className="check-field">
                                  <input
                                    id="type-image"
                                    type="radio"
                                    name="royalty-type"
                                    checked={royaltyType === 1}
                                    onChange={() => setRoyaltyType(1)} />
                                  <label for="type-image" className="ml-5">Royalty For Collection</label>
                                </div>
                                <div className="check-field ml-15">
                                  <input
                                    id="type-dynamic"
                                    type="radio"
                                    name="royalty-type"
                                    checked={royaltyType === 2}
                                    onChange={() => setRoyaltyType(2)} />
                                  <label for="type-dynamic" className="ml-5">Royalty For Items</label>
                                </div>
                              </div>
                              {royaltyType == 1 && (
                                <input
                                  type="number"
                                  name="royalty_percentage"
                                  className="form-control"
                                  placeholder="E.g 4.5"
                                  min="0"
                                  step="any"
                                  value={collectionData.royalty_percentage}
                                  onChange={(event) => handleRoyaltyChange(event)}
                                />
                              )}
                            </div>
                          </div>
                          {royaltyType == 1 && Number(collectionData.royalty_percentage) > 0 && (
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <label>
                                  Royalty Wallet Address{" "}
                                  <span className="text-danger">*</span>{" "}
                                </label>
                                <p>
                                  Royalty percentage will be sent to the
                                  provided wallet address.
                                </p>
                                <input
                                  type="text"
                                  name="royalty_wallet_address"
                                  className="form-control"
                                  placeholder="Wallet address"
                                  value={collectionData.royalty_wallet_address}
                                  onChange={(event) =>
                                    handleSocialLinkChanges(event)
                                  }
                                />
                                <ErrorMessage
                                  component="div"
                                  name="royalty_wallet_address"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                            </div>
                          )}
                          <div className="collection-links">
                            <h3>Links</h3>
                            <InputGroup className="link-input">
                              <InputGroup.Text
                                id="basic-addon1"
                                className="border-top-left-radius"
                              >
                                <i className="fas fa-globe"></i>
                              </InputGroup.Text>
                              <FormControl
                                placeholder="Yoursite.io"
                                name="website_link"
                                value={collectionData.website_link}
                                onChange={(event) =>
                                  handleSocialLinkChanges(event)
                                }
                                className="border-top-right-radius"
                              />
                            </InputGroup>
                            <InputGroup className="link-input">
                              <InputGroup.Text id="basic-addon1">
                                <i className="fab fa-discord"></i>
                              </InputGroup.Text>
                              <FormControl
                                placeholder="https://discord.gg/abcdef"
                                name="discord_link"
                                value={collectionData.discord_link}
                                onChange={(event) =>
                                  handleSocialLinkChanges(event)
                                }
                              />
                            </InputGroup>
                            <InputGroup className="link-input">
                              <InputGroup.Text id="basic-addon1">
                                <i className="fab fa-twitter"></i>
                              </InputGroup.Text>
                              <FormControl
                                placeholder="https://twitter.com/YourTwitterHandle"
                                name="twitter_link"
                                value={collectionData.twitter_link}
                                onChange={(event) =>
                                  handleSocialLinkChanges(event)
                                }
                              />
                            </InputGroup>
                            <InputGroup className="link-input">
                              <InputGroup.Text id="basic-addon1">
                                <i className="fab fa-instagram"></i>
                              </InputGroup.Text>
                              <FormControl
                                placeholder="https://www.instagram.com/YourInstagramHandle"
                                name="instagram_link"
                                value={collectionData.instagram_link}
                                onChange={(event) =>
                                  handleSocialLinkChanges(event)
                                }
                              />
                            </InputGroup>
                            <InputGroup className="link-input">
                              <InputGroup.Text id="basic-addon1">
                                <i className="fab fa-medium-m"></i>
                              </InputGroup.Text>
                              <FormControl
                                placeholder="https://www.medium.com/@YourMediumHandle"
                                name="medium_link"
                                value={collectionData.medium_link}
                                onChange={(event) =>
                                  handleSocialLinkChanges(event)
                                }
                              />
                            </InputGroup>
                            <InputGroup className="link-input">
                              <InputGroup.Text id="basic-addon1">
                                <i className="fab fa-telegram-plane"></i>
                              </InputGroup.Text>
                              <FormControl
                                placeholder="https://t.me/abcdef"
                                name="telegram_link"
                                value={collectionData.telegram_link}
                                onChange={(event) =>
                                  handleSocialLinkChanges(event)
                                }
                              />
                            </InputGroup>
                          </div>
                          {/* <div className="network-selection">
                            <h3>Blockchain - Network</h3>
                            <CustomSelect
                              value={networkSelect}
                              handleChange={handleNetworkSelect}
                              items={[
                                { name: "Polygon", id: 1 },
                              ]}
                              placeHolder={"Network"}
                              idBased
                              nameField={"name"}
                              valueField={"id"}
                            />
                          </div> */}
                          <div className="custom-checkbox mb-4 mt-5">
                            <div className="check-field">
                              <input id="my-check" type="checkbox" checked={termsAgreed} onChange={() => handleTermsChange()} />
                              <label for="my-check">
                                I agree to the following{" "}
                                <Link to="/page/terms" target="_blank">terms & conditions</Link>
                              </label>
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12 create-btn">
                            <button
                              type="submit"
                              className="default-btn border-radius-5 px-5 py-3 mt-4"
                              disabled={
                                !validCollectionName ||
                                props.collectionSave.buttonDisable ||
                                buttonContent != "" ||
                                !termsAgreed
                              }
                            >
                              {buttonContent == ""
                                ? "Create Collection"
                                : buttonContent}
                            </button>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  categories: state.nfts.categoriesList,
  collectionSave: state.nfts.collectionSave,
  collectionNameValidation: state.nfts.collectionNameValidation,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateCollection);
