import React, { useContext, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles, withStyles, styled } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import { Link } from "react-router-dom";
import WidgetsOutlinedIcon from "@material-ui/icons/WidgetsOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import GridOnOutlinedIcon from "@material-ui/icons/GridOnOutlined";
import ExitToAppOutlined from "@material-ui/icons/ExitToAppOutlined";
import { authContext } from "../../authprovider/AuthProvider";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import SettingsIcon from "@material-ui/icons/Settings";
import CustomSearch from "../../../customComponents/CustomSearch";
import CloseIcon from "@material-ui/icons/Close";
import useUserAuthStatus from "../../../customHooks/useUserAuthStatus";

const sideBarStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    zIndex: "999 !important",
  },
  drawerPaper: {
    paddingTop: (props) => (props.drawerWidth < 576 ? "67px" : "70px"),
    minWidth: (props) => (props.drawerWidth < 576 ? "100vw" : "300px"),
    maxWidth: (props) => (props.drawerWidth < 576 ? "100vw" : "420px"),
    overflow: "auto",
  },
  treeView: {
    "& .MuiTreeItem-root ": {
      border: "none",
      borderBottom: `1px solid ${theme.palette.border.primary}`,
      padding : "1rem 0",
    },
  },
}));

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: "0.5rem",
  borderBottom: "1px solid rgba(4, 17, 29, 0.1)",
  "&.Mui-selected": {
    backgroundColor: "transparent",

    "& .MuiTypography-root": {
      backgroundColor: "transparent !important",
    },
  },
  "& .MuiBox-root": {
    padding: 0,
    gap: "0.5rem",
  },
  "& .MuiBox-root:hover": {
    backgroundColor: "transparent !important",
  },
  "& .MuiTreeItem-content": {
    backgroundColor: "transparent",
    color: "rgba(4, 17, 29, 1)",
    borderTopRightRadius: "none",
    borderBottomRightRadius: "none",
    fontWeight: 700,
    fontSize: "1.25em",
    flexDirection: "row-reverse",
    padding: "0 0.5em",

    "&.Mui-expanded": {
      fontWeight: 700,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `transparent`,
      color: "var(--tree-view-color)",
    },
    "& .MuiTreeItem-label": {
      fontWeight: "inherit",
      color: "inherit",
    },
    "& .MuiTypography-caption": {
      fontSize: "1.25em",
    },
    "& .MuiBox-root:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiTypography-root": {
      color: theme.palette.text.primary,
      padding: "0",
      fontSize: "1rem",
      fontWeight: 700,

      "& a": {
        color: theme.palette.text.primary,
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",

        "&:hover": {
          color: theme.themeColor.primary,
        },
      },

      "& button": {
        color: theme.palette.text.primary,
        fontWeight: 700,
        fontSize: "1rem",
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
      },

      "& img": {
        width: "1rem",
        height: "1rem",
      },

      "& .icon": {
        width: "1rem",
        height: "1rem",
        margin: 0,
      },
    },

    "& .MuiTreeItem-iconContainer": {
      "& .MuiSvgIcon-root": {
        fill: theme.palette.text.primary,
      },
    },

    "& .MuiTypography-root:hover": {
      backgroundColor: "transparent",
    },
  },

  "& .MuiCollapse-root": {
    width: "calc(100% - 17px)",

    "& .MuiCollapse-wrapper .MuiTreeItem-root": {
      border: "none",
    },
  },
}));

const StyledTreeItem = (props) => {
  const {
    bgColor,
    labelIcon: LabelIcon,
    linkUrl: LinkUrl,
    labelText,
    link,
    button,
    buttonClickEvent,
    image,
    imageFile,
    labelclass,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          {LabelIcon && (
            <Box component={LabelIcon} sx={{ mr: 1 }} className={labelclass} />
          )}

          <Typography variant="body2">
            {link ? (
              <Link to={LinkUrl}>
                {image && (
                  <>
                    <img src={imageFile} alt="" />
                  </>
                )}
                {labelText}
              </Link>
            ) : button ? (
              <button onClick={buttonClickEvent}>{labelText}</button>
            ) : (
              <>{labelText}</>
            )}
          </Typography>
        </Box>
      }
      {...other}
    />
  );
};

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  ExploreOutlinedIcon: PropTypes.string,
  labelIcon: PropTypes.elementType,
  labelInfo: PropTypes.string,
  linkUrl: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const MobileHeader = (props) => {
  const [drawerWidth, setDrawerWidth] = useState(window.innerWidth);

  const [searchQuery, setSearchQuery] = useState("");

  const classes = sideBarStyles({ drawerWidth: drawerWidth });

  const { auth, hanldeLogout } = useContext(authContext);

  const userAuthStatus = useUserAuthStatus(props.profile);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setDrawerWidth(window.innerWidth);
  };

  useEffect(() => {
    if (drawerWidth > 1200 && props.mobileMenuStatus) {
      props.toggleMobileMenu(false);
    }
  }, [drawerWidth]);

  const { categories } = props;

  const handleSearchQueryChange = (value) => {
    setSearchQuery(value);
  };

  return (
    <>
      <div>
        <Box role="presentation" className={classes.root}>
          <Drawer
            anchor={"right"}
            open={props.mobileMenuStatus}
            onOpen={() => props.toggleMobileMenu(true)}
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              onClose: () => props.toggleMobileMenu(false),
            }}
            onClose={() => props.toggleMobileMenu(false)}
            disableSwipeToOpen={true}
          >
            {drawerWidth < 991 && (
              <>
                <div className="drawer-closer">
                  <CloseIcon onClick={() => props.toggleMobileMenu(false)} />
                </div>
                <CustomSearch
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                  classes={"mobile-search"}
                />
              </>
            )}
            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<KeyboardArrowDownOutlinedIcon />}
              defaultExpandIcon={<ChevronRightOutlinedIcon />}
              className={classes.treeView}
            >
              {drawerWidth < 768 && userAuthStatus.userLoggedIn && (
                <>
                  <StyledTreeItem
                    nodeId="1"
                    labelText="Account"
                    labelIcon={AccountCircleOutlinedIcon}
                    link={false}
                  >
                    <StyledTreeItem
                      nodeId="12"
                      labelText="Profile"
                      labelIcon={AccountCircleOutlinedIcon}
                      link={true}
                      linkUrl={`/profile`}
                    />
                    <StyledTreeItem
                      nodeId="13"
                      labelText="Favourites"
                      labelIcon={FavoriteBorderOutlinedIcon}
                      link={true}
                      linkUrl={`/profile?tab=favorites`}
                    />
                    <StyledTreeItem
                      nodeId="13"
                      labelText="Settings"
                      labelIcon={SettingsIcon}
                      link={true}
                      linkUrl={`/profile-setting`}
                    />
                    <StyledTreeItem
                      nodeId="14"
                      labelText="My Collection"
                      labelIcon={GridOnOutlinedIcon}
                      link={true}
                      linkUrl={`/my-collection`}
                    />
                  </StyledTreeItem>
                </>
              )}
              {/* {drawerWidth < 1200 && userAuthStatus.userLoggedIn && (
                <StyledTreeItem
                  nodeId="1-1"
                  labelText="Create"
                  labelIcon={AddCircleOutlineIcon}
                  link={true}
                  linkUrl={`/create-new-item`}
                >
                </StyledTreeItem>
              )} */}
              {categories.loading ? null : categories.data.categories.length >
                0 ? (
                <StyledTreeItem
                  nodeId="2"
                  labelText="Explore"
                  labelIcon={ExploreOutlinedIcon}
                  link={true}
                  linkUrl={`/explore`}
                >
                  <StyledTreeItem
                    nodeId="41"
                    labelText={"All NFTs"}
                    linkUrl={`/all-nfts`}
                    link={true}
                    image
                    imageFile={window.location.origin + "/assets/images/grid-blue.png"}
                  />
                  {categories.data.categories.filter(cat => cat.status === 1).map((category , index) => (
                    <StyledTreeItem
                      nodeId="41"
                      labelText={category.name}
                      linkUrl={`/explore?tab=${category.category_id}`}
                      link={true}
                      image
                      imageFile={category.picture}
                      key={index}
                    />
                  ))}
                </StyledTreeItem>
              ) : null}
              <StyledTreeItem
                nodeId="4"
                labelText="Resources"
                labelIcon={WidgetsOutlinedIcon}
                link={false}
              >
                {configuration
                  .get("configData.footer_pages1")
                  .map((static_page, index) => (
                    <StyledTreeItem
                      nodeId="41"
                      labelText={static_page.title}
                      linkUrl={`/page/${static_page.static_page_unique_id}`}
                      link={true}
                      key={index}
                    />
                  ))}
              </StyledTreeItem>
              {userAuthStatus.userLoggedIn ? (
                <StyledTreeItem
                  nodeId="5"
                  labelText="Logout"
                  labelIcon={ExitToAppOutlined}
                  linkUrl={`/logout`}
                  link={true}
                />
              ) : 
              <StyledTreeItem
                  nodeId="5"
                  labelText="Login"
                  labelIcon={ExitToAppOutlined}
                  linkUrl={`/login`}
                  link={true}
                />}
            </TreeView>
          </Drawer>
        </Box>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  categories: state.nfts.categoriesList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MobileHeader);
