import { getFbImage, getWhatappImage, getTwitterImage } from './SocialIcons';

export const getDefaultTemplate = () => {
  return `
    <div class="template">
      <div>
      <img style="width:100%;height:auto" src="https://media.npr.org/assets/img/2016/11/04/gettyimages-507938684_wide-7efd875d4be0d7fc06d5753fb02c62cd7fe81511-s1100-c50.jpg" />
      </div>
      <div class="template-body">
        <h4>Donald Trump <a href="https://google.com" target="_blank">@realDonaldTrump</a></h4>
        <a href="#" target="_blank">https://t.co/csX07ZVWGe</a>
        <div class="text-gray">8:10 PM - Jan 07, 2021</div>
        <div class="text-gray flex mt-10">
          <div class="mr-5">627.9k</div>
          <div>115k</div>
        </div>
        <div style="font-size:20px; margin-top:15px">Sentiment</div>
        <div>
          <div class="neutral">NEUTRAL</div>
        </div>
        <div style="float:right">
          <div class="flex shareDiv">
            <div>Share this</div>
            <img src="`+ getFbImage() + `" alt="fb" class="share" />
            <img src="`+ getTwitterImage() + `" alt="fb" class="share" />
            <img src="`+ getWhatappImage() + `" alt="fb" class="share" />
          </div>
        </div>
      </div>
      <style>
        body{
          background-color: #22242c !important;
        }
        .template{
          // background-color:#22242c;
          width:100%;
          min-height:300px;
        }
        .template-body{
          padding:20px;
          color:#fff;
        }
        a{
          color:#1e93bd;
          text-decoration:none;
        }
        .text-gray{
          color:#a5a8ad;
        }
        .flex{
          display:flex;
        }
        .mr-5{
          margin-right:5px;
        }
        .mt-10{
          margin-top:10px;
        }
        .neutral{
          padding:5px 0px 5px 0px;
          text-align:center;
          background-color:#1e93bd;
          width:100px;
          border-radius:15px;
        }
        .share{
          width:20px;
          height:20px;
        }
        .shareDiv{
          column-gap:5px;
        }
      </style>
    </div>
    <style>
    .gjs-row{
      display:flex;
    }
    </style>
    `;
}