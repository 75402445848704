import React, { useState } from "react";
// import { ToastContainer } from "react-toastify";

const AuthenticationLayout = (props) => {
  return (
    <>
      {/* <ToastContainer /> */}
      {props.children}
    </>
  );
};

export default AuthenticationLayout;
