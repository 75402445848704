import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { registerStart } from "../../store/actions/UserAction";
import { connect } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import configuration from "react-global-configuration";


const RegisterIndex = (props) => {
  const history = useHistory();
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (
      !skipRender &&
      !props.registerInputData.loading &&
      Object.keys(props.registerInputData.data).length > 0
    ) {
      console.log(props.registerInputData.data);
      console.log(Object.keys(props.registerInputData.data).length);
      if (props.registerInputData.data.data.user.is_email_verified === 0) {
        history.push("/register-verification");
      } else {
        history.push("/privatekey");
      }
    }
    setSkipRender(false);
  }, [props.registerInputData]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
    confirm_password: Yup.string().required("Required").when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      )
    }),
  });

  const validatePassword = (value) => {
    let error;
    if (!value) {
      error = 'Required';
    } else if (value.length < 6) {
      error = 'At least 6 letters';
    } else {
      let trimmed = value.replaceAll(" ", '');
      if (trimmed !== value) {
        error = "No space allowed";
      }
    }
    return error;
  }

  const register = (values) => {
    props.dispatch(registerStart(values));
  }

  return (
    <>
      <div className="new-login-sec">
        <Container>
          <div className="login-form-sec">
            <Row className="align-items-center">
              <Col md={6}>
                <div className="login-img">
                  <img src="/assets/images/login-img.jpg" alt="" />
                </div>
              </Col>
              <Col md={6}>
                <div className="login-form">
                  <div className="new-login-img">
                    <Link to="/">
                      <Image
                        src={
                          configuration.get("configData.site_logo") ?
                            configuration.get("configData.site_logo")
                            : window.location.origin + "/assets/images/logo-metaocean.png"
                        }
                      />
                    </Link>
                    {/* <img
                      src="/assets/images/logo-metaocean.png"
                      className="mb-3"
                      alt=""
                    /> */}
                  </div>

                  <h2>Create An Account</h2>
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      username: Math.floor(Math.random() * 1000),
                      password: "",
                      confirm_password: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => register(values)}
                  >
                    {({ errors, values, touched }) => (
                      <FORM>
                        <Form.Group className="mb-3">
                          <Form.Label>Name</Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder="Enter Your Name"
                            name="name"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="name"
                            className="text-danger small"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email address</Form.Label>
                          <Field
                            className="form-control"
                            type="email"
                            placeholder="Enter Your Email Address"
                            name="email"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="text-danger small"
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Password</Form.Label>
                          <Field
                            className="form-control"
                            type="password"
                            placeholder="Enter Your Password"
                            name="password"
                            validate={validatePassword}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="text-danger small"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Confirm Password</Form.Label>
                          <Field
                            className="form-control"
                            type="password"
                            placeholder="Re-enter Your Password"
                            name="confirm_password"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="confirm_password"
                            className="text-danger small"
                          />
                        </Form.Group>
                        <div className="new-login-btn mb-3">
                          <Button
                            className="default-btn border-radius-5"
                            type="submit"
                            disabled={props.registerInputData.buttonDisable}
                          >
                            {props.registerInputData.loadingButtonContent ?
                              props.registerInputData.loadingButtonContent
                              : "Register"
                            }
                          </Button>
                        </div>
                        <div className="register-link text-center">
                          Already Have an Account?
                          <Link to="/login">Click Here</Link>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  registerInputData: state.users.registerInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(RegisterIndex);
