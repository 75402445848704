import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import "./Verification.css";
import { useHistory } from "react-router";
import { twoStepAuthLoginStart, twoStepAuthResendCodeStart } from "../../store/actions/UserAction";
import { connect } from "react-redux";
import configuration from "react-global-configuration";

const VerificationIndex = (props) => {
  const history = useHistory();

  const [otp, setOTP] = useState("");
  const [email, setEmail] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const handleChange = (otp) => setOTP(otp);

  useEffect(() => {
    if (localStorage.getItem("email")) {
      setEmail(localStorage.getItem("email"));
    } else {
      let userId = localStorage.getItem("userId");
      let accessToken = localStorage.getItem("accessToken");
      let userLoginStatus = localStorage.getItem("userLoginStatus");
      let authentication = userId && accessToken && userLoginStatus == "true" ? true : false;
      if (authentication) {
        history.push("/");
      } else {
        history.push("/login");
      }
    }
  }, []);

  const verify = e => {
    e.preventDefault();
    props.dispatch(twoStepAuthLoginStart({
      email: email,
      verification_code: otp,
    }));
  };

  const resend = () => {
    props.dispatch(twoStepAuthResendCodeStart({
      email: email,
    }));
  }

  useEffect(() => {
    if (!skipRender &&
      !props.twoStepAuthLogin.loading &&
      Object.keys(props.twoStepAuthLogin.data).length > 0) {
      history.push("/");
    }
    setSkipRender(false);
  }, [props.twoStepAuthLogin]);

  return (
    <>
      <div className="verification-sec">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8} xl={5} lg={6}>
              <Link to="/login">
                <div className="verification-logo-sec">
                  <Link to="/">
                    <Image
                      className="verification-logo"
                      src={
                        configuration.get("configData.site_logo") ?
                          configuration.get("configData.site_logo")
                          : window.location.origin + "/assets/images/logo-metaocean.png"
                      }
                    />
                  </Link>
                </div>
              </Link>
              <div className="verification-box">
                <h4>
                  Please enter the one time password to verify your account
                </h4>
                <p>We emailed a OTP to {email}</p>
                <form onSubmit={verify}>
                  <div className="verification-item">
                    <OtpInput
                      value={otp}
                      onChange={handleChange}
                      numInputs={6}
                      separator={<span>-</span>}
                    />
                  </div>
                  <div className="auth-btn-sec verification-btn-sec">
                    {/* <Button
                                        className="custom-black-btn"
                                    >
                                        Validate
                                    </Button> */}
                    <Button
                      className="default-btn border-radius-5"
                      disabled={!otp || otp.length < 6 || props.twoStepAuthLogin.buttonDisable}
                      type="submit"
                    >
                      {props.twoStepAuthLogin.loadingButtonContent ?
                        props.twoStepAuthLogin.loadingButtonContent
                        : "Validate"
                      }
                    </Button>
                    <Button
                      className="default-btn two border-radius-5"
                      type="button"
                      onClick={() => resend()}
                      disabled={props.resendTwoStepAuthCode.buttonDisable}
                    >
                      {props.resendTwoStepAuthCode.loadingButtonContent ?
                        props.resendTwoStepAuthCode.loadingButtonContent
                        : "Resend"
                      }
                    </Button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  resendTwoStepAuthCode: state.users.resendTwoStepAuthCode,
  twoStepAuthLogin: state.users.twoStepAuthLogin,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(VerificationIndex);
