import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Tab,
  Row,
  Nav,
  InputGroup,
  FormControl,
  Container,
} from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LockIcon from "@material-ui/icons/Lock";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ImageIcon from "@material-ui/icons/Image";
import { authContext } from "../authprovider/AuthProvider";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { connect } from "react-redux";
import PageNotFound from "../404/PageNotFound";
import EditProfilePanel from "./EditProfilePanel";
import EditProfileLoader from "../loaders/EditProfileLoader";
import SettingsSidebar from "./SettingsSidebar";
import NoDataFound from "../NoDataFound/NoDataFound";

const ProfileSettings = (props) => {
  const { auth } = useContext(authContext);

  // useEffect(() => {
  //   if (!auth.loading && auth.accounts != "" && auth.authStatus) {
  //     props.dispatch(fetchUserDetailsStart());
  //   }
  // }, [auth.authStatus , auth.loading]);

  return (
    <>
      <div className="profile-setting pages-padding">
        <div className="profile-setting-wrapper">
          <Row className="mt-3 mt-md-0 g-0">
            <SettingsSidebar />
            <Col md={8} lg={9} className="mt-3 mt-md-0">
              {console.log(props.profile.data)}
              {props.profile.loading ? (
                <Col lg={8}>
                  <EditProfileLoader />
                </Col>
              ) : (
                props.profile.data.user ?
                  <Col lg={8}>
                    <EditProfilePanel />
                  </Col>
                  : <NoDataFound />
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ProfileSettings);
