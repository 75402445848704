import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Tab,
  Row,
  Nav,
  InputGroup,
  FormControl,
  Container,
} from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LockIcon from "@material-ui/icons/Lock";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ImageIcon from "@material-ui/icons/Image";
import { authContext } from "../authprovider/AuthProvider";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { connect } from "react-redux";
import PageNotFound from "../404/PageNotFound";
import AuthenticationPanel from "./AuthenticationPanel";
import SettingsSidebar from "./SettingsSidebar";

const AuthenticationIndex = (props) => {
  const { auth } = useContext(authContext);

  return (
    <>
      <div className="profile-setting pages-padding">
        <div className="profile-setting-wrapper">
          <Row className="mt-3 mt-md-0 g-0">
            <SettingsSidebar />
            <Col md={6} lg={6} className="mt-3 mt-md-0">
              <AuthenticationPanel />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AuthenticationIndex);
